import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faExclamationTriangle,
  faFileAlt,
  faPencilAlt,
  faTrash,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import MUILink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { format } from 'date-fns';
import MUIDataTable, { Display, MUIDataTableColumn } from 'mui-datatables';
import { parse } from 'qs';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import Customer from '../../../../../models/Customer';
import Ticket, { TicketStatus } from '../../../../../models/Ticket';
import { TaskStatus } from '../../../../../models/Task';
import User from '../../../../../models/User';
import { TableFilter } from '../../../../../models/TableFilter';
import Button from '../../../../common/Button';
import TableButton from '../../../../common/TableButton';
import { Column } from '../../../../common/types';
import { tableTranslations } from '../../../../common/utils';
import ViewMachineModal from '../../ViewModal';
import ViewTechsModal from '../ViewTechsModal';
import DeleteModal from '../DeleteModal';

interface IProps {
  history: any;
  location: any;
  match: any;
  tickets: Ticket[];
  loading: boolean;
  showDeleteMachineTicket: (id?: number) => void;
  deleteMachineTicket?: number;
  fetchCustomers: () => void;
  fetchUsers: () => void;
  customers: Record<number, Customer>;
  users: Record<number, User>;
  user: User;
  viewMachine?: number;
  viewTechs?: number;
  toggleMachineView: (machineId?: number) => void;
  toggleMachineTicketTechsView: (ticketId?: number) => void;
}
interface IState {
  columns: Column[];
  filter: TableFilter;
}

const getColumnDefinition = (users: Record<number, User>, { tickets }: any, user: User) => [
  {
    name: 'id',
    label: 'ID',
    options: {
      display: (localStorage.getItem('tickets_list_columns_id') || 'true') as Display,
      filterList: tickets && tickets.split(',').length ? (tickets || '').split(',').map((id: string) => Number(id)) : [],
    }
  },
  {
    name: 'statusText',
    label: 'Estado',
    options: {
      display: (localStorage.getItem('tickets_list_columns_statusText') || 'true') as Display,
      filterList: tickets && tickets.split(',').length ? [] : ['Em espera', 'Em execução'],
      filterType: 'multiselect',
      filterOptions: {
        names: ['Em espera', 'Em execução', 'Concluído'],
      },
    },
  },
  { name: 'limitDateFormatted', label: 'Data Limite', options: { display: (localStorage.getItem('tickets_list_columns_limitDateFormatted') || 'true') as Display } },
  { name: 'machineLabel', label: 'Máquina', options: { display: (localStorage.getItem('tickets_list_columns_machineLabel') || 'true') as Display } },
  { name: 'title', label: 'Título', options: { display: (localStorage.getItem('tickets_list_columns_title') || 'true') as Display } },
  { name: 'customerName', label: 'Cliente', options: { display: (localStorage.getItem('tickets_list_columns_customerName') || 'true') as Display } },
  {
    name: 'responsibles',
    label: 'Técnico(s) Atribuído(s)',
    options: {
      display: (localStorage.getItem('tickets_list_columns_responsibles') || user.role !== 'externaltech' ? 'true' : 'false') as Display,
      filterType: 'multiselect',
      filterOptions: {
        names: Object.values(users).map((u) => u.name).sort(),
        logic: (locations: any[], filters: any[], row: any) => {
          if (filters.length) return filters.map((filter) => !!locations.includes(filter)).filter((l) => !!l).length !== filters.length;
          return false;
        },
      },
    }
  },
  { name: 'costCenter', label: 'Tipo de Custos', options: { display: (localStorage.getItem('tickets_list_columns_costCenter') || 'true') as Display} },
  { name: 'createdFormatted', label: 'Criado em', options: { display: (localStorage.getItem('tickets_list_columns_createdFormatted') || 'true') as Display } },
  {
    name: 'actions',
    label: 'Acções',
    options: {
      display: (localStorage.getItem('tickets_list_columns_actions') || 'true') as Display,
      filter: false,
      customHeadRender: (columnMeta: any) => (
        <TableCell key="-1">
          <div style={{ width: '90px'}}>{columnMeta.label}</div>
        </TableCell>
      ),
    },
  },
] as any[];

class List extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { users, user } = props;

    const filter = this.getTableState();
    const columns = getColumnDefinition(users, parse(props.location.search, { ignoreQueryPrefix: true }) as any, user);

    this.state = {
      columns: this.apendFilterListToColumns(columns, filter.filterList, false),
      filter,
    };

    this.onColumnViewChange = this.onColumnViewChange.bind(this);
    this.customRowRender = this.customRowRender.bind(this);
    this.hideDelete = this.hideDelete.bind(this);
    this.apendFilterListToColumns = this.apendFilterListToColumns.bind(this);
    this.setTableState = this.setTableState.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { location, users, user } = this.props;
    const { filter } = this.state;

    if (JSON.stringify(prevProps.users) !== JSON.stringify(users)) {
      const columns = getColumnDefinition(users, parse(location.search, { ignoreQueryPrefix: true }), user);

      this.setState({
        columns: this.apendFilterListToColumns(columns, filter.filterList, false),
      });
    }
  }

  public componentDidMount() {
    const { fetchCustomers, fetchUsers, user } = this.props;

    fetchCustomers();

    if (user.role !== 'externaltech') {
      fetchUsers();
    }
  }

  public onColumnViewChange(field: string, action: string) {
    const { columns } = this.state;

    columns.find((c) => c.name === field)!.options.display = String(action !== 'remove') as
      | 'true'
      | 'false';

    localStorage.setItem(`tickets_list_columns_${field}`, String(columns.find((c) => c.name === field)!.options.display));

    this.setState({ columns });
  }

  public getTableState() {
    return JSON.parse(localStorage.getItem('tickets_list') || '{"filterList": []}');
  }

  public setTableState() {
    const { filter } = this.state;

    localStorage.setItem('tickets_list', JSON.stringify(filter));
  }

  public apendFilterListToColumns(columns: MUIDataTableColumn[], filterList: string[][], all: boolean): any[] {
    const { location } = this.props;

    filterList.forEach((filter, index) => {
      if (all || index > 1 || !(parse(location.search, { ignoreQueryPrefix: true }) || {}).tickets) {
        (columns[index] || {}).options = {
          ...((columns[index] || {}).options || {}),
          filterList: filter,
        };
      }
    });

    return columns;
  }

  public showDelete(ticketId: number) {
    const { showDeleteMachineTicket } = this.props;

    showDeleteMachineTicket(ticketId);
  }

  public hideDelete() {
    const { showDeleteMachineTicket } = this.props;

    showDeleteMachineTicket();
  }

  public customRowRender(rowData: any[], dataIndex: number, rowIndex: number) {
    const { columns } = this.state;
    const { tickets, toggleMachineView, toggleMachineTicketTechsView, user } = this.props;

    const ticket = tickets[dataIndex] || {};

    return (
      <TableRow key={rowIndex}>
        {columns[0].options.display === 'true' && <TableCell>{rowData[0]}</TableCell>}
        {columns[1].options.display === 'true' && (
          <TableCell>
            <Chip
              style={{
                height: 'auto',
                padding: '3px',
                backgroundColor:
                  ticket.status === TicketStatus.PENDING
                    ? '#7a7a7a'
                    : ticket.status === TicketStatus.EXECUTION
                    ? '#007bff'
                    : ticket.status === TicketStatus.DONE
                    ? '#28a745'
                    : '',
                color: '#ffffff',
              }}
              label={rowData[1]}
            />
          </TableCell>
        )}
        {columns[2].options.display === 'true' && <TableCell style={{
          color: rowData[2] && new Date(rowData[2]).getTime() < Date.now() ? '#cb0000' : 'inherit'
        }}>
          {rowData[2] && new Date(rowData[2]).getTime() < Date.now() ? (
            <FontAwesomeIcon style={{ color: '#cb0000', paddingRight: '5px' }} icon={faExclamationTriangle} />
          ) : null}
          {rowData[2] ? rowData[2] : <b>_</b>}
        </TableCell>}
        {columns[3].options.display === 'true' && <TableCell>
          {rowData[3] && <Button color="primary" onClick={() => toggleMachineView(Number(rowData[3].split(' ')[0]))} variant="outlined" size="small">#{rowData[3]}</Button>}
        </TableCell>}
        {columns[4].options.display === 'true' && <TableCell>{rowData[4]}</TableCell>}
        {columns[5].options.display === 'true' && <TableCell>{rowData[5]}</TableCell>}
        {columns[6].options.display === 'true' && (
          <TableCell>
            <Tooltip title={rowData[6].join(', ')}>
              <span className="machine-length" onClick={() => toggleMachineTicketTechsView(rowData[0])}>
                <FontAwesomeIcon icon={faUser} />
                <span>{rowData[6].length}</span>
              </span>
            </Tooltip>
          </TableCell>
        )}
        {columns[7].options.display === 'true' && <TableCell>{rowData[7]}</TableCell>}
        {columns[8].options.display === 'true' && <TableCell>{rowData[8]}</TableCell>}
        {columns[9].options.display === 'true' &&  <TableCell>
          {ticket.status === TicketStatus.DONE && user.role !== 'externaltech' && (
            <MUILink href={`${process.env.REACT_APP_API_URL}/api/machines/tickets/ticket/${rowData[0]}.pdf`} target="_blank">
              <TableButton>
                <FontAwesomeIcon icon={faFileAlt} />
              </TableButton>
            </MUILink>
          )}
          <Link to={`/dashboard/machines/tickets/ticketid/${rowData[0]}/edit`}>
            <TableButton>
              <FontAwesomeIcon icon={faPencilAlt} />
            </TableButton>
          </Link>
          {user.role === 'admin' || user.role === 'admintech' ? (
            <TableButton onClick={() => this.showDelete(rowData[0])}>
              <FontAwesomeIcon icon={faTrash} />
            </TableButton>
          ) : null}
        </TableCell>}
      </TableRow>
    );
  }

  public render() {
    const { columns, filter } = this.state;
    const { customers, deleteMachineTicket, tickets, user, users, viewMachine, viewTechs } = this.props;

    return (
      <>
        <Box>
          <Grid container>
            <Grid item sm={6}>
              <Typography variant="h6" component="h2">Máquinas</Typography>
              <Breadcrumbs className="breadcrumbs" separator={<FontAwesomeIcon icon={faChevronRight} size="xs" />} aria-label="breadcrumb">
                <Typography color="textPrimary">SATs</Typography>
              </Breadcrumbs>
            </Grid>

            {user.role === 'admin' || user.role === 'admintech' ? (
              <Grid item sm={6} style={{ textAlign: 'right' }}>
                <Link to="/dashboard/machines/tickets/new">
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon />}
                  >Adicionar</Button>
                </Link>
              </Grid>
            ) : null}
          </Grid>
        </Box>
        
        <Box mt={2} pb={2}>
          <Card>
            <CardContent>
              
              <MUIDataTable
                title={''}
                data={tickets.map(
                  (t) => {
                    const responsibles: string[] = [];
                    Object.values(t.tasks || {}).filter((task) =>
                      t.status === TicketStatus.DONE ||
                      task.status !== TaskStatus.DONE
                    ).forEach((task) => {
                      (task.technicians || []).forEach((id: number) => {
                        if (responsibles.indexOf((users[id] || {}).name) === -1) {
                          responsibles.push((users[id] || {}).name);
                        }
                      })
                    });

                    return {
                      ...t,
                      machineLabel: t.machineId ? `${t.machineId}${t.machineBrand ? ' ' + t.machineBrand : ''}${t.machineModel ? ' ' + t.machineModel : ''}` : undefined,
                      customerName: ((customers || {})[t.customerId] || {}).name,
                      limitDateFormatted: t.limitDate ? format(new Date(t.limitDate), 'yyyy-MM-dd') : undefined,
                      statusText: 
                        t.status === TicketStatus.PENDING
                          ? 'Em espera'
                          : t.status === TicketStatus.EXECUTION
                          ? 'Em execução'
                          : t.status === TicketStatus.DONE
                          ? 'Concluído'
                          : '',
                      responsibles,
                      costCenter: t.costCenter === 'warranty'
                        ? 'Garantia'
                        : t.costCenter === 'sell'
                        ? 'Ao abrigo do valor de venda'
                        : t.costCenter === 'invoice'
                        ? `Facturação (${t.invoiceno || 'NA'})`
                        : '',
                      createdFormatted: format(new Date(t.created), 'yyyy-MM-dd'),
                    };
                  }
                )}
                columns={columns}
                options={{
                  rowsPerPage: 100,
                  textLabels: tableTranslations,
                  selectableRows: 'none',
                  elevation: 0,
                  customRowRender: this.customRowRender,
                  onColumnViewChange: this.onColumnViewChange,
                  searchText: filter.searchText ?? undefined,
                  onTableChange: (action, tableState) => {
                    if (action === 'filterChange' || action === 'search') {
                      this.setState({
                        filter: {
                          filterList: tableState.filterList,
                          searchText: tableState.searchText,
                        },
                        columns: this.apendFilterListToColumns(columns, tableState.filterList, true),
                      }, this.setTableState);
                    }
                  }
                }}
              />
            </CardContent>
          </Card>
        </Box>
        {viewTechs ? <ViewTechsModal /> : null}
        {viewMachine ? <ViewMachineModal /> : null}
        {deleteMachineTicket ? <DeleteModal id={deleteMachineTicket} handleClose={this.hideDelete} /> : null}
      </>
    );
  }
};

export default withRouter(List);