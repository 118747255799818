import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { FunctionComponent } from 'react';
import { DropzoneArea, FileObject, PreviewIconProps } from 'material-ui-dropzone'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Button from '../../../common/Button';
import { checkIfImage } from '../../../common/utils';
import { CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

interface IProps {
  id?: number;

  observations?: string;
  youtubeLink?: string;
  catalogOriginalname?: string;
  catalogFilename?: string;
  filesLoading: boolean;
  files: File[];
  marketplaceVisibility?: boolean;
  marketplaceEmphasis?: boolean;
  marketplacePriceVisibillity?: boolean;
  marketplacePriceType?: 'installPrice' | 'truckPrice';

  submitted: boolean;

  handleChange: (name: string, value: any) => void;
  handleFiles: (files: any[]) => void;
}

const FormOptionals: FunctionComponent <IProps> = (properties) => {
  const {
    id,

    observations,
    youtubeLink,
    catalogOriginalname,
    catalogFilename,
    filesLoading,
    files,
    marketplaceVisibility,
    marketplaceEmphasis,
    marketplacePriceVisibillity,
    marketplacePriceType,

    submitted,
  
    handleChange,
    handleFiles,
  } = properties;

  const handleUploadClick = (event: any) => {
    const file = event.target.files[0];

    handleChange('catalog', file);
    handleChange('catalogOriginalname', file.name);
  };

  const dropFile = () => {
    handleChange('catalog', undefined);
    handleChange('catalogOriginalname', undefined);
  };

  const handleDrop = (droppedItem: any) => {
    if (!droppedItem.destination) return;

    var updatedList = [...files];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    handleChange('files', updatedList);
  };

  return (
    <>
      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Gerais</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item sm={12} className="dropzone">
                <DropzoneArea
                  dropzoneText="Arraste e large fotografias aqui"
                  acceptedFiles={['image/*']}
                  initialFiles={files}
                  filesLimit={999}
                  getFileLimitExceedMessage={(filesLimit: number) =>
                    `Número máximo de ficheiros ultrapassado. Apenas ${filesLimit} são permitidos`}
                  getFileAddedMessage={(fileName: string) => `Ficheiro ${fileName} adicionado como sucesso`}
                  getFileRemovedMessage={(fileName: string) => `Ficheiro ${fileName} removido.`}
                  getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
                    let message = "Ficheiro ".concat(rejectedFile.name, " foi rejeitado. ");
                
                    if (!acceptedFiles.includes(rejectedFile.type)) {
                      message += 'Tipo de ficheiro não suportado. ';
                    }
                
                    if (rejectedFile.size > maxFileSize) {
                      let size = '';

                      if (maxFileSize >= 1048576) {
                        size = maxFileSize / 1048576 + ' megabytes';
                      } else if (maxFileSize >= 1024) {
                        size = maxFileSize / 1024 + ' kilobytes';
                      } else {
                        size = maxFileSize + ' bytes';
                      }
                    
                      message += `O ficheiro é muito grande. Tamanho limite de ${size}.`;
                    }
                
                    return message;
                  }}
                  getPreviewIcon={(file: FileObject, classes: PreviewIconProps) => <span />}
                  maxFileSize={2147483648}
                  onChange={handleFiles}
                />
              </Grid>
              <Grid item sm={12}>
                {filesLoading
                  ? <CircularProgress />
                  : files.length ? (
                    <div className="thumbnail-slider">
                      <DragDropContext onDragEnd={handleDrop}>
                        <Droppable droppableId="list-container" direction="horizontal">
                          {(provided: any) => (
                            <div
                              className="list-container"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {files.map((file: File, index: number) => (
                                <Draggable key={index} draggableId={index.toString()} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      className="item-container"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        userSelect: 'none',
                                        background: snapshot.isDragging ? '#cb0000' : 'white',
                                        ...provided.draggableProps.style
                                      }}
                                    >
                                      <div key={index} className="image">
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="drop"
                                          onClick={() => {
                                            const doc = document.getElementsByClassName('MuiDropzonePreviewList-imageContainer')[index];
                                            if (doc && doc.childNodes[1]) {
                                              // @ts-ignore
                                              doc.childNodes[1].click();
                                            }
                                          }}
                                        />
                                        {checkIfImage(file.name) ? (
                                          <img
                                            alt=""
                                            role="presentation"
                                            key={index}
                                            src={URL.createObjectURL(file)}
                                          />
                                        ) : (<div>{file.name}</div>)}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  ) : null
                }
                <i>Primeira imagem será usada como cover. Clique e arraste para ordenar.</i>
              </Grid>
              <Grid item sm={12}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    label="Observações"
                    value={observations || ''}
                    variant="standard"
                    inputProps={{ maxLength: 3000 }}
                    onChange={(event: any) => handleChange('observations', event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    label="Link Vídeo Youtube"
                    value={youtubeLink || ''}
                    variant="standard"
                    inputProps={{ maxLength: 255 }}
                    onChange={(event: any) => handleChange('youtubeLink', event.target.value)}
                    type="url"
                  />
                </FormControl>
              </Grid>
              <Grid item sm={6}></Grid>
              <Grid item sm={6}>
                <Box mt={2}>
                  <FormControl margin="dense" fullWidth>
                    <Typography variant="caption">Catálogo</Typography>
                    {catalogOriginalname ? (
                      <Typography variant="body1">
                        {catalogFilename ? (
                          <Link
                            href={`${process.env.REACT_APP_API_URL}/api/files/machines/${catalogFilename}`}
                            target="_blank"
                          >{catalogOriginalname}</Link>
                        ) : catalogOriginalname}
                        <DeleteIcon style={{ cursor: 'pointer', marginBottom: '-5px' }} color="secondary" onClick={dropFile} />
                      </Typography>
                    ) : (
                      <Button variant="contained" component="label" color="secondary">
                        Upload File
                        <input type="file" hidden onChange={handleUploadClick} />
                      </Button>
                    )}
                  </FormControl>
                </Box>
              </Grid>
              <Grid item sm={6}></Grid>
              <Grid item sm={6}>
                <FormControl margin="dense" fullWidth>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={marketplaceVisibility || false} />}
                      label="Visível no marketplace"
                      onChange={(event: any) => handleChange('marketplaceVisibility', event.target.checked)}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                {marketplaceVisibility ? (
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      disabled
                      label="Link para o marketplace"
                      value={id ? 'https://gotec.projectbox.pt/marketplace/machine/' + (id || '') : 'Guarde primeiro'}
                      variant="standard"
                      type="url"
                    />
                  </FormControl>
                ) : null}
              </Grid>
              <Grid item sm={12}>
                {marketplaceVisibility ? (
                  <FormControl margin="dense" fullWidth>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={marketplaceEmphasis || false} />}
                        label="Máquina em destaque"
                        onChange={(event: any) => handleChange('marketplaceEmphasis', event.target.checked)}
                      />
                    </FormGroup>
                  </FormControl>
                ) : null}
                {marketplaceVisibility ? (
                  <FormControl margin="dense" fullWidth>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={marketplacePriceVisibillity || false} />}
                        label="Preço visível no marketplace"
                        onChange={(event: any) => handleChange('marketplacePriceVisibillity', event.target.checked)}
                      />
                    </FormGroup>
                  </FormControl>
                ) : null}
                {marketplaceVisibility && marketplacePriceVisibillity ? (
                  <Box ml={4}>
                    <FormControl margin="dense" required error={submitted && !marketplacePriceType} fullWidth>
                      <FormLabel component="legend">Tipo de preço</FormLabel>
                      <RadioGroup
                        value={marketplacePriceType || ''}
                        onChange={(event: any) => handleChange('marketplacePriceType', event.target.value)}
                        style={{ flexDirection: 'row' }}
                      >
                        <FormControlLabel value="installPrice" control={<Radio />} label="Mostrar preço com instalação" />
                        <FormControlLabel value="truckPrice" control={<Radio />} label="Mostrar preço em cima do camião" />
                      </RadioGroup>
                      {submitted && !marketplacePriceType ? <FormHelperText>Deve seleccionar o tipo de preço</FormHelperText> : null}
                    </FormControl>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default FormOptionals;
