import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faChevronRight, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import MUIDataTable, { Display, MUIDataTableColumn } from 'mui-datatables';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';

import User from '../../../../models/User';
import { TableFilter } from '../../../../models/TableFilter';
import Button from '../../../common/Button';
import TableButton from '../../../common/TableButton';
import { Column } from '../../../common/types';
import { tableTranslations } from '../../../common/utils';
import ViewModal from '../ViewModal';

interface IProps {
  users: User[];
  loading: boolean;
  toggleUserView: (userId: number | undefined) => void;
  toggleUser: (id: number, active: boolean) => void;
  user: User;
  view?: number;
}
interface IState {
  columns: Column[];
  filter: TableFilter;
}

class List extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const filter = this.getTableState();
    const columns = this.apendFilterListToColumns([
      { name: 'id', label: 'ID', options: { display: (localStorage.getItem('users_list_columns_id') || 'true') as Display } },
      { name: 'name', label: 'Nome', options: { display: (localStorage.getItem('users_list_columns_name') || 'true') as Display } },
      { name: 'email', label: 'Email', options: { display: (localStorage.getItem('users_list_columns_email') || 'true') as Display } },
      { name: 'role', label: 'Perfil', options: { display: (localStorage.getItem('users_list_columns_role') || 'true') as Display } },
      { name: 'active', label: 'Estado', options: { display: (localStorage.getItem('users_list_columns_active') || 'true') as Display } },
      {
        name: 'actions',
        label: 'Acções',
        options: {
          display: (localStorage.getItem('users_list_columns_actions') || 'true') as Display,
          filter: false,
          customHeadRender: (columnMeta) => (
            <TableCell key="-1">
              <div style={{ width: '90px'}}>{columnMeta.label}</div>
            </TableCell>
          ),
        }
      },
    ], filter.filterList);

    this.state = {
      columns,
      filter,
    };

    this.onColumnViewChange = this.onColumnViewChange.bind(this);
    this.customRowRender = this.customRowRender.bind(this);
    this.apendFilterListToColumns = this.apendFilterListToColumns.bind(this);
    this.setTableState = this.setTableState.bind(this);
  }

  public onColumnViewChange(field: string, action: string) {
    const { columns } = this.state;

    columns.find((c) => c.name === field)!.options.display = String(action !== 'remove') as
      | 'true'
      | 'false';

    localStorage.setItem(`users_list_columns_${field}`, String(columns.find((c) => c.name === field)!.options.display));

    this.setState({ columns });
  }

  public handleSwitchChange(id: number, checked: boolean) {
    const { toggleUser } = this.props;

    toggleUser(id, checked);
  }

  public customRowRender(rowData: any[], dataIndex: number, rowIndex: number) {
    const { columns } = this.state;
    const { toggleUserView, user } = this.props;

    return (
      <TableRow key={rowIndex}>
        {columns[0].options.display === 'true' && <TableCell>{rowData[0]}</TableCell>}
        {columns[1].options.display === 'true' && <TableCell>{rowData[1]}</TableCell>}
        {columns[2].options.display === 'true' && <TableCell>{rowData[2]}</TableCell>}
        {columns[3].options.display === 'true' && <TableCell>{
          rowData[3] === 'admin' ? 'Admininstrador' :
          rowData[3] === 'admincommercial' ? 'Administrador Comercial' :
          rowData[3] === 'commercial' ? 'Comercial' :
          rowData[3] === 'admintech' ? 'Administrador Técnico' :
          rowData[3] === 'tech' ? 'Técnico' :
          rowData[3] === 'administrative' ? 'Administrativo' :
          rowData[3] === 'externaltech' ? 'Técnico Externo' : ''
        }</TableCell>}
        {columns[4].options.display === 'true' && <TableCell>
          <Switch
            checked={rowData[4] === true}
            disabled={user.role !== 'admin'}
            onChange={(checked: boolean) => this.handleSwitchChange(rowData[0], checked)}
            offColor="#eef1f5"
            onColor="#28a745"
            uncheckedIcon={
              <div
                style={{
                  position: 'absolute',
                  left: '-15px',
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  color: '#b6c3ce',
                }}
              >
                Inactivo
              </div>
            }
            checkedIcon={
              <div
                style={{
                  position: 'absolute',
                  left: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  color: '#ffffff',
                }}
              >
                Activo
              </div>
            }
            width={90}
          />
        </TableCell>}
        {columns[5].options.display === 'true' && <TableCell>
          <TableButton onClick={() => toggleUserView(rowData[0])}>
            <FontAwesomeIcon icon={faEye} />
          </TableButton>
          <Link to={`/dashboard/users/userid/${rowData[0]}/edit`}>
            <TableButton>
              <FontAwesomeIcon icon={faPencilAlt} />
            </TableButton>
          </Link>
        </TableCell>}
      </TableRow>
    );
  }

  public getTableState() {
    return JSON.parse(localStorage.getItem('users_list') || '{"filterList": []}');
  }

  public setTableState() {
    const { filter } = this.state;

    localStorage.setItem('users_list', JSON.stringify(filter));
  }

  public apendFilterListToColumns(columns: MUIDataTableColumn[], filterList: string[][]): any[] {
    filterList.forEach((filter, index) => {
      (columns[index] || {}).options = {
        ...((columns[index] || {}).options || {}),
        filterList: filter,
      };
    });

    return columns;
  }

  public render() {
    const { columns, filter } = this.state;
    const { user, users, view } = this.props;

    return (
      <>
        <Box>
          <Grid container>
            <Grid item sm={6}>
              <Typography variant="h6" component="h2">Utilizadores</Typography>
              <Breadcrumbs className="breadcrumbs" separator={<FontAwesomeIcon icon={faChevronRight} size="xs" />} aria-label="breadcrumb">
                <Typography color="textPrimary">Lista de Utilizadores</Typography>
              </Breadcrumbs>
            </Grid>

            {user.role === 'admin' && (
              <Grid item sm={6} style={{ textAlign: 'right' }}>
                <Link to="/dashboard/users/new">
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon />}
                  >Adicionar</Button>
                </Link>
              </Grid>
            )}
          </Grid>
        </Box>
        
        <Box mt={2} pb={2}>
          <Card>
            <CardContent>
              <MUIDataTable
                title={''}
                data={users.filter((u) => user.role === 'admin' || u.id === user.id)}
                columns={columns}
                options={{
                  rowsPerPage: 100,
                  textLabels: tableTranslations,
                  selectableRows: 'none',
                  elevation: 0,
                  customRowRender: this.customRowRender,
                  onColumnViewChange: this.onColumnViewChange,
                  searchText: filter.searchText ?? undefined,
                  onTableChange: (action, tableState) => {
                    if (action === 'filterChange' || action === 'search') {
                      this.setState({
                        filter: {
                          filterList: tableState.filterList,
                          searchText: tableState.searchText,
                        },
                        columns: this.apendFilterListToColumns(columns, tableState.filterList),
                      }, this.setTableState);
                    }
                  }
                }}
              />
            </CardContent>
          </Card>
        </Box>
        {view ? <ViewModal /> : null}
     </>
    );
  }
};

export default List;