import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faWallet, faTrashAlt, faSignature, faTrash, faImage } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getType } from 'mime';
import { format } from 'date-fns';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { Component } from 'react';

import {
  CreateMachineTicketTaskComment,
  DeleteMachineTicketTaskExpense,
  DeleteMachineTicketTaskComment,
  EditMachineTicketTask
} from '../../../../../../actions';
import axios from '../../../../../../api/axios';
import APIFile from '../../../../../../models/File';
import Task from '../../../../../../models/Task';
import User from '../../../../../../models/User';
import Zone from '../../../../../../models/Zone';
import Button from '../../../../../common/Button';
import TableButton from '../../../../../common/TableButton';
import { checkIfImage } from '../../../../../common/utils';
import ExpenseModal from '../ExpenseModal';
import MoveModal from '../MoveModal';
import DeleteModal from '../DeleteModal';

interface IProps {
  task: Task;
  user: User;
  users: User[];
  zoneId?: number;
  zones: Zone[];
  createMachineTicketTaskComment: (properties: CreateMachineTicketTaskComment) => void;
  deleteMachineTicketTaskExpense: (properties: DeleteMachineTicketTaskExpense) => void;
  deleteMachineTicketTaskComment: (properties: DeleteMachineTicketTaskComment) => void;
  editMachineTicketTask: (properties: EditMachineTicketTask) => void;
  openSign: (ticketId: number, taskId: number) => void;
  closeMachineTicketTask: () => void;
  redirectReady: boolean;
  redirectReadyTask: boolean;
  loading: boolean;
  viewOnly: boolean;
}

interface IState {
  comment: string;
  expenses?: Element;
  title: string;
  technicians: number[];
  move?: Element;
  edit?: keyof IState;
  dirty: boolean;
  filesLoading: boolean;
  files: File[];
  submitted: boolean;
  deleteTask: boolean;
}

interface StatusFile extends File {
  sysfilename: string;
}

export async function createFile(file: APIFile): Promise<StatusFile> {
  let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/files/machine-tickets/${file.filename}`, { responseType: 'blob' });
  let metadata = {
    type: getType(file.originalname) || 'image/png',
    name: file.filename,
  };

  const f = new File([response.data], file.originalname, metadata) as StatusFile;
  f.sysfilename = file.filename;
  return f;
}

class TaskModal extends Component<IProps, IState> {
  private dropArea = React.createRef();

  constructor(props: IProps) {
    super(props);

    this.state = {
      comment: '',
      title: '',
      technicians: [],
      files: [],
      dirty: false,
      filesLoading: false,
      submitted: false,
      deleteTask: false,
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.openExpenses = this.openExpenses.bind(this);
    this.openSign = this.openSign.bind(this);
    this.hideExpenses = this.hideExpenses.bind(this);
    this.openMove = this.openMove.bind(this);
    this.hideMove = this.hideMove.bind(this);
    this.hideDelete = this.hideDelete.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.delTicketTask = this.delTicketTask.bind(this);
    this.triggerEdition = this.triggerEdition.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { redirectReady, redirectReadyTask } = this.props;

    if (redirectReady !== prevProps.redirectReady && redirectReady) {
      this.setState({ comment: '' });
    }
    if (redirectReadyTask !== prevProps.redirectReadyTask && redirectReadyTask) {
      this.setState({ edit: undefined });
    }
  }

  public handleChange(field: keyof IState, value: any) {
    this.setState({
      [field]: value,
      dirty: field === 'title' || field === 'technicians'
    } as Pick<IState, 'dirty'>);
  }

  public loadFiles(mFiles: APIFile[]) {
    Promise.all((mFiles || [])
      .map((file) => createFile(file))
    ).then((files) => {
      this.setState({ files, filesLoading: false });
    });
  }

  public handleFiles(files: File[]) {
    this.setState({ files, dirty: true });
  }

  public hideExpenses() {
    this.setState({ expenses: undefined });
  }

  public openExpenses(event: any) {
    this.setState({ expenses: event.currentTarget });
  }

  public openSign() {
    const { openSign, task } = this.props;

    openSign(task.ticketid, task.id);
  }

  public delTicketTask() {
    this.setState({ deleteTask: true });
  }

  public hideDelete() {
    this.setState({ deleteTask: false });
  }

  public openMove(event: any) {
    this.setState({ move: event.currentTarget });
  }

  public hideMove() {
    this.setState({ move: undefined });
  }

  public triggerEdition(key: 'title' | 'technicians' | 'files') {
    const { viewOnly, task } = this.props;
    const { title, technicians, files } = task;

    if (viewOnly) {
      return;
    }

    return this.setState({
      edit: key,
      title,
      technicians,
      submitted: false,
      filesLoading: true,
    }, () => this.loadFiles(files));
  }

  public handleComment() {
    const { createMachineTicketTaskComment, task } = this.props;
    const { comment } = this.state;

    createMachineTicketTaskComment({
      ticketid: task.ticketid,
      taskid: task.id,
      comment,
    });
  }

  public handleCancel() {
    this.setState({ edit: undefined });
  }

  public hasErrors() {
    const { title, technicians } = this.state;

    return !title.length || !technicians.length;
  }

  public handleSubmit() {
    const { editMachineTicketTask, task } = this.props;
    const { title, technicians, files } = this.state;
    const { ticketid, id, status } = task;

    if (this.hasErrors()) {
      return this.setState({ submitted: true });
    }

    editMachineTicketTask({
      ticketid,
      id,
      title,
      technicians,
      status,
      files,
    })
  }

  public render() {
    const {
      deleteMachineTicketTaskExpense,
      deleteMachineTicketTaskComment,
      closeMachineTicketTask,
      loading,
      task,
      user,
      users,
      zoneId,
      zones,
      viewOnly,
    } = this.props;
    const {
      deleteTask,
      comment,
      expenses,
      move,
      title,
      technicians,
      files,
      filesLoading,
      edit,
      dirty,
      submitted
    } = this.state;

    return (
      <>
        <Dialog maxWidth="md" fullWidth open>
          <DialogTitle>
            <span>Executar tarefa - {task.title}</span>
            <IconButton edge="end" size="small" className="dialog-close-button" onClick={() => closeMachineTicketTask()}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item sm={10}>
                <Box mb={2} mr={2}>
                  {edit === 'title' ? (
                    <>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          error={submitted && !title.length}
                          label="Título"
                          value={title || ''}
                          variant="standard"
                          onChange={(event: any) => this.handleChange('title', event.target.value)}
                          helperText={submitted && !title.length ? 'Deve inserir um título' : ''}
                        />
                      </FormControl>
                      {dirty === true ? (
                        <Box mt={1}>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box mr={1}>
                              <Button
                                variant="contained"
                                color="default"
                                size="small"
                                onClick={this.handleCancel}
                              >Cancelar</Button>
                            </Box>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              startIcon={<SaveIcon />}
                              onClick={this.handleSubmit}
                            >{loading || filesLoading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
                          </div>
                        </Box>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <Typography className="subtitle back" variant="subtitle2">Título</Typography>
                      <Typography variant="body2" onDoubleClick={() => this.triggerEdition('title')}>{task.title}</Typography>
                    </>
                  )}
                </Box>
                <Box mb={2} mr={2}>
                  {edit === 'technicians' ? (
                    <>
                      <FormControl fullWidth>
                        <Autocomplete
                          autoComplete
                          clearOnEscape
                          openOnFocus
                          options={users}
                          value={users.filter((u) => technicians.indexOf(u.id) !== -1)}
                          multiple
                          getOptionLabel={(option: User) => option.name}
                          onChange={(event, value: User[]) => this.handleChange('technicians', value ? value.map((v) => v.id) : undefined)}
                          renderInput={(params: any) =>
                            <TextField
                              {...params}
                              error={submitted && !technicians.length}
                              label="Seleccione técnico(s)"
                              helperText={submitted && !technicians.length ? 'Deve seleccionar pelo menos um técnico' : ''}
                            />
                          }
                        />
                      </FormControl>
                      {dirty === true ? (
                        <Box mt={1}>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box mr={1}>
                              <Button
                                variant="contained"
                                color="default"
                                size="small"
                                onClick={this.handleCancel}
                              >Cancelar</Button>
                            </Box>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              startIcon={<SaveIcon />}
                              onClick={this.handleSubmit}
                            >{loading || filesLoading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
                          </div>
                        </Box>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <Typography className="subtitle back" variant="subtitle2">Técnicos responsáveis</Typography>
                      {(task.technicians || []).map((t) =>
                        <Chip
                          key={t}
                          label={((users || []).find((u) => u.id === t) || {}).name}
                          onDoubleClick={() => this.triggerEdition('technicians')}
                        ></Chip>
                      )}
                    </>
                  )}
                </Box>
                <Box mb={2} mr={2} className="noselect">
                  <Typography className="subtitle back" variant="subtitle2">Fotografias</Typography>

                  {task.files && task.files.length && edit !== 'files' ? (
                    <div style={{ cursor: 'pointer' }} className="thumbnail-slider" onDoubleClick={() => this.triggerEdition('files')}>
                      {task.files.map((file: APIFile, index: number) => (
                        <div key={index} className="image">
                          {checkIfImage(file.filename) ? (
                            <img
                              alt=""
                              role="presentation"
                              src={`${process.env.REACT_APP_API_URL}/api/files/machine-tickets/${file.filename}`}
                            />
                          ) : (<div>{file.filename}</div>)}
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {(!task.files || !task.files.length) && !viewOnly && edit !== 'files' ? (
                    <Button onClick={() => this.triggerEdition('files')}>
                      <div>
                        <FontAwesomeIcon size="4x" icon={faImage} />
                        <Typography component="div" variant="caption">Adicionar imagem</Typography>
                      </div>
                    </Button>
                  ) : null}
                  {edit === 'files' ? (
                    <>
                      {filesLoading
                      ? <CircularProgress />
                      : (
                        <>
                          <div className="dropzone">
                            <DropzoneArea
                              dropzoneText="Arraste e large fotografias aqui"
                              acceptedFiles={['image/*']}
                              initialFiles={files}
                              filesLimit={999}
                              getFileLimitExceedMessage={(filesLimit: number) =>
                                `Número máximo de ficheiros ultrapassado. Apena ${filesLimit} é permitido`}
                              getFileAddedMessage={(fileName: string) => `Ficheiro ${fileName} adicionado como sucesso`}
                              getFileRemovedMessage={(fileName: string) => `Ficheiro ${fileName} removido.`}
                              getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
                                let message = 'Ficheiro '.concat(rejectedFile.name, ' foi rejeitado. ');
                            
                                if (!acceptedFiles.includes(rejectedFile.type)) {
                                  message += 'Tipo de ficheiro não suportado. ';
                                }
                            
                                if (rejectedFile.size > maxFileSize) {
                                  let size = '';

                                  if (maxFileSize >= 1048576) {
                                    size = maxFileSize / 1048576 + ' megabytes';
                                  } else if (maxFileSize >= 1024) {
                                    size = maxFileSize / 1024 + ' kilobytes';
                                  } else {
                                    size = maxFileSize + ' bytes';
                                  }
                                
                                  message += `O ficheiro é muito grande. Tamanho limite de ${size}.`;
                                }
                            
                                return message;
                              }}
                              onChange={this.handleFiles}
                            />
                          </div>
                          {files.length ? (
                            <div className="thumbnail-slider">
                              {files.map((file: File, index: number) => (
                                <div key={index} className="image">
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    className="drop"
                                    onClick={() => {
                                      const doc = document.getElementsByClassName('MuiDropzonePreviewList-imageContainer')[index];
                                      if (doc && doc.childNodes[1]) {
                                        // @ts-ignore
                                        doc.childNodes[1].click();
                                      }
                                    }}
                                  />
                                  {checkIfImage(file.name) ? (
                                    <img
                                      alt=""
                                      role="presentation"
                                      key={index}
                                      src={URL.createObjectURL(file)}
                                    />
                                  ) : (<div>{file.name}</div>)}
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </>
                      )}
                      {dirty === true ? (
                        <Box mt={1}>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box mr={1}>
                              <Button
                                variant="contained"
                                color="default"
                                size="small"
                                onClick={this.handleCancel}
                              >Cancelar</Button>
                            </Box>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              startIcon={<SaveIcon />}
                              onClick={this.handleSubmit}
                            >{loading || filesLoading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
                          </div>
                        </Box>
                      ) : null}
                    </>
                  ) : null}
                </Box>
                {Object.values(task.expenses || {}).length ? (
                  <Box mb={2}>
                    <Typography className="subtitle back" variant="subtitle2">Despesas</Typography>

                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Inserido em</TableCell>
                          <TableCell>Realizado em</TableCell>
                          <TableCell>Tipo</TableCell>
                          <TableCell>Descrição/Técnico</TableCell>
                          <TableCell>Qtd</TableCell>
                          <TableCell>Preço Uni.</TableCell>
                          <TableCell>Total</TableCell>
                          {!viewOnly ? (<TableCell></TableCell>) : null}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.values(task.expenses || {}).map((expense, index) => (
                          <TableRow key={index}>
                            <TableCell>{format(new Date(expense.datetime), 'yyyy-MM-dd HH:mm')}</TableCell>
                            <TableCell>{format(new Date(expense.date), 'yyyy-MM-dd HH:mm')}</TableCell>
                            <TableCell>{
                              expense.type === 'material'
                              ? 'Material'
                              : expense.type === 'travels'
                              ? 'Deslocações'
                              : expense.type === 'manHours'
                              ? 'Mão de obra'
                              : expense.type === 'others'
                              ? 'Outros'
                              : ''
                            }</TableCell>
                            <TableCell>{expense.description || (users.find((u) => u.id === expense.regUserId) || {}).name}</TableCell>
                            <TableCell>{expense.quantity}</TableCell>
                            <TableCell>{Number(expense.price).toFixed(2)}€</TableCell>
                            <TableCell>{Number(expense.price * expense.quantity).toFixed(2)}€</TableCell>
                            {!viewOnly ? (
                              <TableCell style={{ textAlign: 'right' }}>
                                <TableButton
                                  onClick={() => deleteMachineTicketTaskExpense({
                                    ticketid: expense.ticketid,
                                    taskid: expense.taskid,
                                    id: expense.id,
                                  })}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </TableButton>
                              </TableCell>
                            ) : null}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                ) : null}
                {Object.values(task.comments || {}).length ? (
                  <Box mb={2}>
                    <Typography className="subtitle back" variant="subtitle2">Comentários</Typography>

                    <Table>
                      <TableBody>
                        {Object.values(task.comments || {}).map((c, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography variant="body2">{c.comment}</Typography>
                              <Typography variant="caption">{(users.find((u) => u.id === c.userid) || {}).name} em {format(new Date(c.datetime), 'yyyy-MM-dd HH:mm')}</Typography>
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                              <TableButton
                                onClick={() => deleteMachineTicketTaskComment({
                                  ticketid: c.ticketid,
                                  taskid: c.taskid,
                                  id: c.id,
                                })}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </TableButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                ) : null}
                <Box mb={2}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Comentários"
                      value={comment || ''}
                      variant="standard"
                      multiline
                      minRows={3}
                      onChange={(event: any) => this.handleChange('comment', event.target.value)}
                    />
                  </FormControl>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {comment ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<SaveIcon />}
                        onClick={this.handleComment}
                      >{loading ? <CircularProgress size={24} /> : 'Gravar Comentário'}</Button>
                    ) : null}
                  </div>
                </Box>
                {(task.signs || []).length ? (
                  <Box mb={2}>
                    <Typography className="subtitle back" variant="subtitle2">Assinaturas</Typography>

                    <Table>
                      <TableBody>
                        {(task.signs || []).map((s, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <a href={`${process.env.REACT_APP_API_URL}/api/files/machines/${s.signature}`} target="_blank" rel="noreferrer">
                                <img
                                  alt={s.signature}
                                  src={`${process.env.REACT_APP_API_URL}/api/files/machines/${s.signature}`}
                                  style={{ maxHeight: '60px', maxWidth: '120px' }}
                                />
                              </a>
                            </TableCell>
                            <TableCell>{format(new Date(s.datetime), 'yyyy-MM-dd HH:mm')}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                ) : null}
              </Grid>
              <Grid item sm={2}>
                {!viewOnly ? (
                  <Box pb={1}>
                    <Button
                      variant="contained"
                      color="default"
                      fullWidth
                      style={{ justifyContent: 'flex-start' }}
                      startIcon={<FontAwesomeIcon icon={faArrowRight} />}
                      onClick={this.openMove}
                    >
                      Mover
                    </Button>
                  </Box>
                ) : null}
                {!viewOnly || user.role === 'externaltech' ? (
                  <Box pb={1}>
                    <Button
                      variant="contained"
                      color="default"
                      fullWidth
                      style={{ justifyContent: 'flex-start' }}
                      startIcon={<FontAwesomeIcon icon={faWallet} />}
                      onClick={this.openExpenses}
                    >
                      Re. Serviço
                    </Button>
                  </Box>
                ) : null}
                <Box pb={1}>
                  <Button
                    variant="contained"
                    color="default"
                    fullWidth
                    style={{ justifyContent: 'flex-start' }}
                    startIcon={<FontAwesomeIcon icon={faSignature} />}
                    onClick={this.openSign}
                  >
                    Assinar
                  </Button>
                </Box>
                {!viewOnly ? (
                  <Box pb={1}>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      style={{ justifyContent: 'flex-start' }}
                      startIcon={<FontAwesomeIcon icon={faTrash} />}
                      onClick={this.delTicketTask}
                    >
                      Apagar
                    </Button>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        {expenses ? <ExpenseModal anchorEl={expenses} task={task} users={users} zoneId={zoneId} zones={zones} handleClose={this.hideExpenses} /> : null}
        {move ? <MoveModal anchorEl={move} task={task} handleClose={this.hideMove} /> : null}
        {deleteTask ? <DeleteModal task={task} handleClose={this.hideDelete} /> : null}
      </>
    );
  }
}

export default TaskModal;
