import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import User from '../../../../models/User';
import Button from '../../../common/Button';

interface IProps {
  user: User;
  toggleUserView: (userId?: number) => void;
}

const ViewModal: FunctionComponent <IProps> = (properties) => {
  const { user, toggleUserView } = properties;
  const {
    id,
    email,
    name,
    role,
    price,
  } = user;

  return (
    <Dialog open onClose={() => toggleUserView()}>
      <DialogTitle>
        <FontAwesomeIcon className="header-icon" icon={faUser} />
        <span style={{ marginLeft: '8px' }}>Detalhes de utilizador - {name}</span>
        <IconButton edge="end" size="small" className="dialog-close-button" onClick={() => toggleUserView()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} alignItems="center">
          <Grid item sm={6}>
            <Typography variant="body2">
              <b>Nome</b>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body2">{name}</Typography>
          </Grid>
          <Grid item sm={12}>
            <Divider />
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body2">
              <b>Email</b>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body2">{email}</Typography>
          </Grid>
          <Grid item sm={12}>
            <Divider />
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body2">
              <b>Perfil</b>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body2">
              {role === 'admin' ? 'Admininstrador'
              : role === 'admincommercial' ? 'Administrador Comercial'
              : role === 'commercial' ? 'Comercial'
              : role === 'admintech' ? 'Administrador Técnico'
              : role === 'tech' ? 'Técnico'
              : role === 'administrative' ? 'Administrativo'
              : role === 'externaltech' ? 'Técnico Externo'
              : ''}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Divider />
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body2">
              <b>Preço por hora</b>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body2">{(price || 0).toFixed(2)}€</Typography>
          </Grid>
          <Grid item sm={12}>
            <Divider />
          </Grid>
       </Grid>
      </DialogContent>
      <Box p={2} pt={0}>
        <DialogActions>
          <Box mr={1}>
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<FontAwesomeIcon icon={faPencilAlt} />}
              component={Link}
              to={`/dashboard/users/userid/${id}/edit`}
            >Editar</Button>
          </Box>
          <Button color="default" variant="contained" onClick={() => toggleUserView()}>Fechar</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ViewModal;