import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  toggleSupplierRepsView,
  createSupplierRep,
  editSupplierRep,
  deleteSupplierRep,
  CreateSupplierRep,
  EditSupplierRep,
  DeleteSupplierRep
} from '../../../../actions';
import { IAppState } from '../../../../reducers/index';
import ViewRepsModal from './ViewRepsModal';

const mapStateToProps = ({ suppliers }: IAppState) => ({
  supplierId: suppliers.repsView.supplierId || 0,
  supplier: suppliers.suppliers[suppliers.repsView.supplierId || 0] || {},
  loading: suppliers.repsView.loading,
  redirectReady: suppliers.repsView.redirectReady,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    toggleSupplierRepsView: (customerId?: number) =>
      dispatch(toggleSupplierRepsView(customerId)),
    createSupplierRep: (properties: CreateSupplierRep) =>
      dispatch(createSupplierRep.request(properties)),
    editSupplierRep: (properties: EditSupplierRep) =>
      dispatch(editSupplierRep.request(properties)),
    deleteSupplierRep: (properties: DeleteSupplierRep) =>
      dispatch(deleteSupplierRep.request(properties)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewRepsModal);