import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import Link from '@material-ui/core/Link';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import Machine from '../../../../models/Machine';

interface IProps {
  machine: Machine;
}

const checkIfImage = (filename: string) => {
  return /\.(jpg|jpeg|gif|png|gif)$/.test(filename.toLowerCase());
};

const Optionals: FunctionComponent <IProps> = (properties) => {
  const {
    id,
    
    files,
    observations,
    youtubeLink,
    catalogOriginalname,
    catalogFilename,
    marketplaceVisibility,
    marketplaceEmphasis,
    marketplacePriceVisibillity,
    marketplacePriceType,
  } = properties.machine;

  return (
    <Box pt={2}>
      <Card>
        <CardContent>
          <Box mb={2}>
            <Typography className="subtitle" component="b">Gerais</Typography>
          </Box>
        
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <GridList cellHeight={120} cols={6}>
                {files.filter((f) => checkIfImage(f.filename)).map((f, index) => (
                  <GridListTile key={index} cols={1}>
                    <Link
                      key={index}
                      href={`${process.env.REACT_APP_API_URL}/api/files/machines/${f.filename}`}
                      target="_blank"
                    >
                      <img
                        alt=""
                        src={`${process.env.REACT_APP_API_URL}/api/files/machines/${f.filename}`}
                        style={{ maxHeight: '100%' }}
                      />
                    </Link>
                  </GridListTile>
                ))}
              </GridList>
            </Grid>

            {files.filter((f) => !checkIfImage(f.filename)).map((f, index) => (
              <Grid key={index} item sm={12}>
                <Link
                  href={`${process.env.REACT_APP_API_URL}/api/files/machines/${f.filename}`}
                  target="_blank"
                >{f.originalname}</Link>
              </Grid>
            ))}
            {observations ? (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Observações C</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{observations}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
            {youtubeLink ? (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Link Vídeo Youtube</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{youtubeLink}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
            {catalogFilename ? (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Catálogo</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    {catalogFilename ? (
                      <Link
                        href={`${process.env.REACT_APP_API_URL}/api/files/machines/${catalogFilename}`}
                        target="_blank"
                      >{catalogOriginalname}</Link>
                    ) : null}
                  </Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
            {marketplaceVisibility ? (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Visível no marketplace</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{'https://gotec.projectbox.pt/marketplace/machine/' + (id || '')}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
            {marketplaceVisibility ? (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Máquina em destaque</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{marketplaceVisibility ? 'Sim' : 'Não'}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
            {marketplaceVisibility && marketplaceEmphasis ? (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Máquina em destaque</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{marketplaceEmphasis ? 'Sim' : 'Não'}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
            {marketplaceVisibility && marketplacePriceVisibillity ? (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Preço visível no marketplace</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{marketplacePriceVisibillity ? 'Sim' : 'Não'}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
            {marketplaceVisibility && marketplacePriceVisibillity ? (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Tipo de Preço</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{
                    marketplacePriceType === 'installPrice'
                    ? 'Mostrar preço com instalação'
                    : marketplacePriceType === 'truckPrice'
                    ? 'Mostrar preço em cima do camião'
                    : ''}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default Optionals;
