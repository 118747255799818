import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUserTag, faPencilAlt, faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import React, { Component, Fragment } from 'react';

import {
  CreateSupplierRep,
  EditSupplierRep,
  DeleteSupplierRep
} from '../../../../actions';
import Supplier from '../../../../models/Supplier';
import { SupplierRep } from '../../../../models/Rep';
import TableButton from '../../../common/TableButton';
import Button from '../../../common/Button';
import { validateEmail } from '../../../../data';

interface IProps {
  supplierId: number;
  supplier: Supplier;
  loading: boolean;
  toggleSupplierRepsView: (customerId?: number) => void;
  createSupplierRep: (properties: CreateSupplierRep) => void;
  editSupplierRep: (properties: EditSupplierRep) => void;
  deleteSupplierRep: (properties: DeleteSupplierRep) => void;
  redirectReady: boolean;
}
interface IState {
  id?: number;
  name: string;
  telephone: string;
  email: string;

  submitted: boolean;
  showForm: boolean;
}

class ViewRepsModal extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      name: '',
      telephone: '',
      email: '',
      submitted: false,
      showForm: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  
  public componentDidUpdate(prevProps: IProps) {
    const { redirectReady } = this.props;

    if (redirectReady !== prevProps.redirectReady && redirectReady) {
      this.setState({
        id: undefined,
        name: '',
        telephone: '',
        email: '',
        submitted: false,
        showForm: false,
      });
    }
  }

  public add() {
    this.setState({
      id: undefined,
      name: '',
      telephone: '',
      email: '',
      submitted: false,
      showForm: true,
    });
  }

  public edit(rep: SupplierRep) {
    this.setState({
      id: rep.id,
      name: rep.name,
      telephone: rep.telephone,
      email: rep.email,
      submitted: false,
      showForm: false,
    });
  }

  public delete(rep: SupplierRep) {
    const { deleteSupplierRep } = this.props;

    deleteSupplierRep({ id: rep.id, supplierId: rep.supplierId });
  }

  public hasError() {
    const { name, email, telephone } = this.state;

    return !name.length || !email.length || !validateEmail(email) || !telephone.length;
  }

  public save() {
    const { supplierId, createSupplierRep, editSupplierRep } = this.props;
    const { id, name, email, telephone } = this.state;

    if (this.hasError()) {
      return this.setState({ submitted: true });
    }

    if (!id) {
      return createSupplierRep({
        supplierId: supplierId || 0,
        name,
        email,
        telephone,
      });
    }

    return editSupplierRep({
      supplierId: supplierId || 0,
      id: id || 0,
      name,
      email,
      telephone,
    });
  }

  public cancel() {
    this.setState({
      showForm: false,
      submitted: false,
      id: undefined,
      name: '',
      telephone: '',
      email: '',
    })
  }

  public handleChange(name: keyof IState, event: any) {
    this.setState({ [name]: event.target.value } as Pick<IState, 'name'>);
  }

  public render() {
    const { supplier, loading, toggleSupplierRepsView } = this.props;
    const { name: supplierName, reps } = supplier;
    const { id, name, telephone, email, showForm , submitted} = this.state;

    return (
      <Dialog maxWidth="md" fullWidth open onClose={() => toggleSupplierRepsView()}>
        <DialogTitle>
          <FontAwesomeIcon className="header-icon" icon={faUserTag} />
          <span style={{ marginLeft: '8px' }}>Pessoas de contacto da {supplierName}</span>
          <IconButton edge="end" size="small" className="dialog-close-button" onClick={() => toggleSupplierRepsView()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <Grid container>
              <Grid item sm={12} style={{ textAlign: 'center' }}>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container>
                <Grid item sm={12} style={{ textAlign: 'right' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon />}
                    onClick={this.add}
                  >Adicionar</Button>
                </Grid>
              </Grid>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Telefone</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell style={{ width: '85px' }}>Acções</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reps.map((rep) => (
                    <Fragment key={rep.id}>
                      {id === rep.id ? (
                        <TableRow>
                          <TableCell>
                            <FormControl fullWidth>
                              <TextField
                                error={submitted && !name.length}
                                label="Nome"
                                required
                                value={name}
                                variant="standard"
                                onChange={(event: any) => this.handleChange('name', event)}
                                inputProps={{ maxLength: 45 }}
                                helperText={submitted && !name.length ? 'Deve introduzir o nome do representante' : ''}
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <FormControl fullWidth>
                              <TextField
                                error={submitted && !telephone.length}
                                label="Telefone"
                                value={telephone}
                                variant="standard"
                                required
                                onChange={(event: any) => this.handleChange('telephone', event)}
                                inputProps={{ maxLength: 45 }}
                                helperText={submitted && !telephone.length ? 'Deve introduzir o telefone do representante' : ''}
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <FormControl fullWidth>
                              <TextField
                                error={submitted && (!email.length || !validateEmail(email))}
                                label="Email"
                                value={email}
                                variant="standard"
                                required
                                onChange={(event: any) => this.handleChange('email', event)}
                                inputProps={{ maxLength: 45 }}
                                helperText={submitted && (!email.length || !validateEmail(email)) ? 'Deve introduzir um email de representante válido' : ''}
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <TableButton style={{ backgroundColor: '#cb0000', color: '#ffffff' }} onClick={() => this.save()}>
                              <FontAwesomeIcon icon={faSave} />
                            </TableButton>
                            <TableButton onClick={() => this.cancel()}>
                              <FontAwesomeIcon icon={faTimes} />
                            </TableButton>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell>{rep.name}</TableCell>
                          <TableCell>{rep.telephone}</TableCell>
                          <TableCell>{rep.email}</TableCell>
                          <TableCell>
                            <TableButton onClick={() => this.edit(rep)}>
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </TableButton>
                            <TableButton onClick={() => this.delete(rep)}>
                              <FontAwesomeIcon icon={faTrash} />
                            </TableButton>
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))}
                </TableBody>
                {showForm ? (
                <TableFooter>
                  <TableRow>
                    <TableCell>
                      <FormControl fullWidth>
                        <TextField
                          error={submitted && !name.length}
                          label="Nome"
                          required
                          value={name}
                          variant="standard"
                          onChange={(event: any) => this.handleChange('name', event)}
                          inputProps={{ maxLength: 45 }}
                          helperText={submitted && !name.length ? 'Deve introduzir o nome do representante' : ''}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <TextField
                          error={submitted && !telephone.length}
                          label="Telefone"
                          value={telephone}
                          variant="standard"
                          required
                          onChange={(event: any) => this.handleChange('telephone', event)}
                          inputProps={{ maxLength: 45 }}
                          helperText={submitted && !telephone.length ? 'Deve introduzir o telefone do representante' : ''}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <TextField
                          error={submitted && (!email.length || !validateEmail(email))}
                          label="Email"
                          value={email}
                          variant="standard"
                          required
                          onChange={(event: any) => this.handleChange('email', event)}
                          inputProps={{ maxLength: 45 }}
                          helperText={submitted && (!email.length || !validateEmail(email)) ? 'Deve introduzir um email de representante válido' : ''}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TableButton style={{ backgroundColor: '#cb0000', color: '#ffffff' }} onClick={() => this.save()}>
                        <FontAwesomeIcon icon={faSave} />
                      </TableButton>
                      <TableButton onClick={() => this.cancel()}>
                        <FontAwesomeIcon icon={faTimes} />
                      </TableButton>
                    </TableCell>
                  </TableRow>
                </TableFooter>
                ) : null}
              </Table>
            </>
          )}
        </DialogContent>
        <Box p={2} pt={0}>
          <DialogActions>
            <Button color="default" variant="contained" onClick={() => toggleSupplierRepsView()}>Fechar</Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
}

export default ViewRepsModal;