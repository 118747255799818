import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faChevronRight, faEnvelope, faPhone, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import { parse } from 'qs';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Prompt } from 'react-router';

import { CreateCustomer, EditCustomer } from '../../../../actions/customers';
import { activityTypes, countries, validateEmail } from '../../../../data';
import Customer from '../../../../models/Customer';
import { ZoneMeta } from '../../../../models/Zone';
import Button from '../../../common/Button';

interface IProps {
  createCustomer: (properties: CreateCustomer) => void;
  customer?: Customer;
  editCustomer: (properties: EditCustomer) => void;
  fetchZoneList: () => void;
  history: any;
  location: any;
  loading: boolean;
  redirectReady: boolean;
  redirectCustomerId?: number;
  zones: ZoneMeta[];
}
interface IState {
  id?: number;
  name: string;
  email: string;
  telephone: string;
  customerTaxNumber: string;
  activityType: string;
  hasVat: boolean;
  vat?: number;
  language: 'pt' | 'en' | 'fr';
  address: string;
  city: string;
  country: string;
  zoneId?: number;
  status?: string;
  submitted: boolean;
  dirty: boolean;
}

class CustomerForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const {
      id,
      name,
      email,
      telephone,
      customerTaxNumber,
      activityType,
      hasVat,
      vat,
      language,
      address,
      city,
      country,
      zoneId,
      status,
    } = props.customer || {};

    this.state = {
      id,
      name: name || '',
      email: email || '',
      telephone: telephone || '',
      customerTaxNumber: customerTaxNumber || '',
      activityType: activityType || '',
      hasVat: hasVat || false,
      vat,
      language: language || 'pt',
      address: address || '',
      city: city || '',
      country: country || '',
      zoneId,
      status,
      submitted: false,
      dirty: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidMount() {
    const { fetchZoneList } = this.props;

    fetchZoneList();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { customer, history, location, redirectReady, redirectCustomerId } = this.props;

    if (JSON.stringify(prevProps.customer) !== JSON.stringify(customer)) {
      const {
        id,
        name,
        email,
        telephone,
        customerTaxNumber,
        activityType,
        hasVat,
        vat,
        language,
        address,
        city,
        country,
        zoneId,
        status,
      } = customer || {};
  
      this.setState({
        id,
        name: name || '',
        email: email || '',
        telephone: telephone || '',
        customerTaxNumber: customerTaxNumber || '',
        activityType: activityType || '',
        hasVat: hasVat !== null ? hasVat || false : true,
        vat,
        language: language || 'pt',
        address: address || '',
        city: city || '',
        country: country || '',
        zoneId,
        status,
        submitted: false,
      });
    }

    if (redirectReady !== prevProps.redirectReady && redirectReady) {
      this.setState({ dirty: false }, () => {
        const { origin, proposalId } = parse(location.search, { ignoreQueryPrefix: true });
        const { id } = customer || {};

        history.push(
          origin === 'proposal'
            ? proposalId
              ? `/dashboard/customers/proposals/proposalid/${proposalId}/edit?origin=customer&customerId=${redirectCustomerId || id}`
              : `/dashboard/customers/proposals/new?origin=customer&customerId=${redirectCustomerId || id}`
            : '/dashboard/customers/list'
        );
      });
    }
  }

  public hasErrors() {
    const { email, name, customerTaxNumber, hasVat, vat } = this.state;

    if (
      !name.length ||
      !customerTaxNumber.length ||
      (!!email && !validateEmail(email)) ||
      (hasVat && (
        !vat ||
        (vat || 0) < 0 ||
        (vat || 0) > 100
      ))
    ) {
      return true;
    }

    return false;
  }

  public handleSubmit() {
    const { createCustomer, editCustomer } = this.props;
    const {
      id,
      activityType,
      hasVat,
      vat,
      language,
      address,
      city,
      country,
      customerTaxNumber,
      email,
      name,
      telephone,
      zoneId,
      status,
    } = this.state;

    if (this.hasErrors()) {
      return this.setState({ submitted: true });
    }

    if (id) {
      editCustomer({
        id,
        activityType: activityType || undefined,
        hasVat,
        vat: hasVat ? vat || undefined : undefined,
        language,
        address: address || undefined,
        city: city || undefined,
        country: country || undefined,
        customerTaxNumber,
        email: email || undefined,
        name,
        telephone: telephone || undefined,
        zoneId: zoneId || undefined,
        status: status || undefined,
      });
    } else {
      createCustomer({
        activityType: activityType || undefined,
        hasVat,
        vat: hasVat ? vat || undefined : undefined,
        language,
        address: address || undefined,
        city: city || undefined,
        country: country || undefined,
        customerTaxNumber,
        email: email || undefined,
        name,
        telephone: telephone || undefined,
        zoneId: zoneId || undefined,
        status: status || undefined,
      });
    }
  }

  public handleChange(field: string, event: any) {
    const { checked, type, value } = event.target;

    this.setState({
      [field]: type === 'checkbox' ? checked : value,
      dirty: true,
    } as Pick<IState, 'dirty'>);
  }

  public render() {
    const { loading, location, zones } = this.props;
    const {
      id,
      activityType,
      hasVat,
      vat,
      language,
      address,
      city,
      country,
      customerTaxNumber,
      email,
      name,
      telephone,
      zoneId,
      status,
      submitted,
      dirty,
    } = this.state;

    const { origin, proposalId } = parse(location.search, { ignoreQueryPrefix: true });

    return (
      <div className="dashboard-container">
        <Prompt
          when={dirty}
          message='Existem alterações que não foram gravadas. Tem a certeza que pretende sair do formulário?'
        />

        <Box>
          <Typography variant="h6" component="h2">Clientes</Typography>
          <Breadcrumbs className="breadcrumbs" separator={<FontAwesomeIcon icon={faChevronRight} size="xs" />} aria-label="breadcrumb">
            <Link color="inherit" to="/dashboard/customers/list">
              Lista de Clientes
            </Link>
            <Typography color="textPrimary">{id ? `Editar ${name}` : 'Adicionar'}</Typography>
          </Breadcrumbs>

          <Grid style={{ justifyContent: 'flex-end' }} container>
            <Grid style={{ display: 'flex' }} item>
              <Box mr={1}>
                <Link to="/dashboard/customers/list">
                  <Button color="default" variant="contained">Cancelar</Button>
                </Link>
              </Box>
              <Button
                disabled={loading}
                variant="contained"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={this.handleSubmit}
              >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <Card>
            <CardContent>
              <Box p={2}>
                <Typography className="subtitle" component="b">Informações</Typography>
              </Box>

              <Box m={2}>
                <Grid container spacing={4}>
                  <Grid sm={6} item>
                    <Grid container spacing={4}>
                      <Grid sm={12} item>
                        <FormControl fullWidth>
                          <TextField
                            error={submitted && !name.length}
                            label="Nome da empresa"
                            required
                            value={name}
                            variant="standard"
                            onChange={(event: any) => this.handleChange('name', event)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <FontAwesomeIcon className="form-icon" icon={faBuilding} />
                                </InputAdornment>
                              )
                            }}
                            helperText={submitted && !name.length ? 'Deve introduzir o nome da empresa' : ''}
                          />
                        </FormControl>
                      </Grid>
                      <Grid sm={12} item>
                        <FormControl fullWidth>
                          <TextField
                            label="Telefone"
                            value={telephone}
                            variant="standard"
                            onChange={(event: any) => this.handleChange('telephone', event)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <FontAwesomeIcon className="form-icon" icon={faPhone} />
                                </InputAdornment>
                              )
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid sm={12} item>
                        <Grid container spacing={2}>
                          <Grid sm={6} item>
                            <FormControl fullWidth margin="dense">
                              <FormGroup row>
                                <FormControlLabel
                                  control={<Checkbox checked={hasVat || false} />}
                                  label="Tem IVA"
                                  onChange={(event: any) => this.handleChange('hasVat', event)}
                                />
                              </FormGroup>
                            </FormControl>
                          </Grid>
                          <Grid sm={6} item>
                            {hasVat === true ? (
                              <FormControl fullWidth>
                                <TextField
                                  error={submitted && (!vat || ((vat || 0) < 0) || ((vat || 0) > 100))}
                                  label="IVA"
                                  value={vat || ''}
                                  variant="standard"
                                  type="number"
                                  inputProps={{ step: 0.01, min: 0, max: 100 }}                  
                                  InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                                  onChange={(event: any) => this.handleChange('vat', event)}
                                  helperText={submitted && (!vat || ((vat || 0) < 0) || ((vat || 0) > 100)) ? 'Deve introduzir uma percentagem válida' : ''}
                                />
                              </FormControl>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid sm={6} item>
                    <Grid container spacing={4}>
                      <Grid sm={12} item>
                        <FormControl fullWidth>
                          <TextField
                            error={submitted && !!email && !validateEmail(email)}
                            label="Email"
                            value={email}
                            variant="standard"
                            onChange={(event: any) => this.handleChange('email', event)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <FontAwesomeIcon className="form-icon" icon={faEnvelope} />
                                </InputAdornment>
                              )
                            }}
                            helperText={submitted && !!email && !validateEmail(email) ? 'Deve introduzir um email válido' : ''}
                          />
                        </FormControl>
                      </Grid>
                      <Grid sm={12} item>
                        <FormControl fullWidth>
                          <TextField
                            error={submitted && !customerTaxNumber.length}
                            label="Número de contribuinte"
                            required
                            value={customerTaxNumber}
                            variant="standard"
                            onChange={(event: any) => this.handleChange('customerTaxNumber', event)}
                            helperText={submitted && !customerTaxNumber.length ? 'Deve introduzir o número de contribuinte' : ''}
                          />
                        </FormControl>
                      </Grid>
                      <Grid sm={12} item>
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            label="Tipo de actividade"
                            select
                            value={activityType}
                            variant="standard"
                            onChange={(event: any) => this.handleChange('activityType', event)}
                          >
                            <MenuItem value="" style={{ color: '#c1c1c1' }}>Nenhuma</MenuItem>
                            {activityTypes.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box mt={2} mb={2}>
          <Card>
            <CardContent>
              <Box p={2}>
                <Typography className="subtitle" component="b">Localização</Typography>
              </Box>

              <Box m={2}>
                <Grid container spacing={4}>
                  <Grid sm={12} item>
                    <FormControl fullWidth>
                      <TextField
                        label="Morada"
                        fullWidth
                        value={address}
                        variant="standard"
                        onChange={(event: any) => this.handleChange('address', event)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faMapMarkedAlt} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Grid sm={6} item>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        label="Cidade"
                        value={city}
                        variant="standard"
                        onChange={(event: any) => this.handleChange('city', event)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid sm={6} item>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        label="País"
                        select
                        value={country}
                        variant="standard"
                        onChange={(event: any) => this.handleChange('country', event)}
                      >
                        {countries.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid sm={6} item>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        label="Zona"
                        select
                        value={zoneId || ''}
                        variant="standard"
                        onChange={(event: any) => this.handleChange('zoneId', event)}
                      >
                        {zones.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>

        <Box mt={2} mb={2}>
          <Card>
            <CardContent>
              <Box p={2}>
                <Typography className="subtitle" component="b">Outros</Typography>
              </Box>

              <Box m={2}>
                <Grid container spacing={4}>
                  <Grid sm={6} item>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        label="Língua"
                        select
                        value={language}
                        variant="standard"
                        onChange={(event: any) => this.handleChange('language', event)}
                      >
                        <MenuItem value="pt">PT</MenuItem>
                        <MenuItem value="en">EN</MenuItem>
                        <MenuItem value="fr">FR</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid sm={6} item>
                    <FormControl fullWidth>
                      <TextField
                        label="Estado"
                        fullWidth
                        value={status}
                        variant="standard"
                        onChange={(event: any) => this.handleChange('status', event)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>

        <Box pb={2}>
          <Grid style={{ justifyContent: 'flex-end' }} container>
            <Grid style={{ display: 'flex' }} item>
              <Box mr={1}>
                <Link to={
                    origin === 'proposal'
                    ? proposalId
                      ? `/dashboard/customers/proposals/proposalid/${proposalId}/edit?origin=customer`
                      : '/dashboard/customers/proposals/new?origin=customer'
                    : '/dashboard/customers/list'
                  }>
                  <Button color="default" variant="contained">Cancelar</Button>
                </Link>
              </Box>
              <Button
                disabled={loading}
                variant="contained"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={this.handleSubmit}
              >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
};

export default CustomerForm;
