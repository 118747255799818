import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faTable } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

const ListComp: FunctionComponent <{}> = () => (
  <>
    <Box>
      <Grid container>
        <Grid item sm={6}>
          <Typography variant="h6" component="h2">Definições</Typography>
        </Grid>
      </Grid>
    </Box>

    <Box mt={2} pb={2}>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <List>
            <Link to={`/dashboard/definitions/zones`}>
              <Card>
                <ListItem button style={{ padding: '26px' }}>
                  <ListItemIcon>
                    <FontAwesomeIcon size="3x" icon={faGlobe} />
                  </ListItemIcon>
                  <ListItemText inset primary="Zonas" />
                </ListItem>
              </Card>
            </Link>
          </List>
        </Grid>
        <Grid item sm={4}>
          <List>
            <Link to={`/dashboard/definitions/dynamicfields`}>
              <Card>
                <ListItem button style={{ padding: '26px' }}>
                  <ListItemIcon>
                    <FontAwesomeIcon size="3x" icon={faTable} />
                  </ListItemIcon>
                  <ListItemText inset primary="Campos dinâmicos" />
                </ListItem>
              </Card>
            </Link>
          </List>
        </Grid>
      </Grid>
    </Box>
  </>
);

export default ListComp;