import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faUserTag, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import { DatePicker } from '@material-ui/pickers';
import { parse } from 'qs';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Prompt } from 'react-router';

import { CreateCustomerProposal, EditCustomerProposal } from '../../../../../actions/customers';
import Customer from '../../../../../models/Customer';
import { DynamicValue } from '../../../../../models/DynamicField';
import { ProposalStatus } from '../../../../../models/Proposal';
import Proposal from '../../../../../models/Proposal';
import Machine from '../../../../../models/Machine';
import User from '../../../../../models/User';
import Button from '../../../../common/Button';
import TableButton from '../../../../common/TableButton';
import { getCoverImage } from '../../../../common/utils';
import ViewModal from '../../../Machines/ViewModal';

interface IProps {
  createCustomerProposal: (properties: CreateCustomerProposal) => void;
  customers: Customer[];
  proposal?: Proposal;
  editCustomerProposal: (properties: EditCustomerProposal) => void;
  fetchCustomers: () => void;
  fetchMachines: () => void;
  fetchMachineStatus: () => void;
  fetchUsers: () => void;
  history: any;
  location: any;
  loading: boolean;
  redirectReady: boolean;
  machines: Machine[]
  machineStatus: DynamicValue[];
  users: User[];
  toggleMachineView: (machineId?: number) => void;
  viewMachine?: number;
}
interface IState {
  commercialUserId?: number;
  customerId?: number;
  id?: number;
  date: Date;
  machineId?: number;
  priceSelection?: string;
  discount?: number;
  total?: number;
  deliveryDays?: number;
  opportunityObservations?: string;
  status: ProposalStatus;
  proposalObservations?: string;
  submitted: boolean;
  dirty: boolean;
}

class Form extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const {
      id,
      commercialUserId,
      date,
      customerId,
      machineId,
      priceSelection,
      discount,
      total,
      opportunityObservations,
      deliveryDays,
      status,
      proposalObservations,
    } = props.proposal || {};

    const query = parse(props.location.search, { ignoreQueryPrefix: true });

    const state = {
      id,
      commercialUserId,
      date: date || new Date(),
      customerId,
      machineId,
      priceSelection,
      discount,
      total,
      opportunityObservations,
      deliveryDays,
      status: status || ProposalStatus.OPORTUNITY,
      proposalObservations,
      submitted: false,
      dirty: false,
    };

    this.state = query.origin === 'customer'
      ? {
        ...JSON.parse(localStorage.getItem('proposalToCustomer') || JSON.stringify(state)),
        customerId: query.customerId && !isNaN(Number(query.customerId)) ? Number(query.customerId) : undefined
      }
      : state;

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.addCustomer = this.addCustomer.bind(this);
  }

  public componentDidMount() {
    const { fetchCustomers, fetchMachines, fetchMachineStatus, fetchUsers } = this.props;

    fetchCustomers();
    fetchMachines();
    fetchMachineStatus();
    fetchUsers();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { machines, proposal, history, redirectReady } = this.props;

    const {
      id,
      commercialUserId,
      date,
      customerId,
      machineId,
      priceSelection,
      discount,
      total,
      deliveryDays,
      opportunityObservations,
      status,
      proposalObservations,
    } = proposal || {};


    if (
      JSON.stringify(prevProps.proposal) !== JSON.stringify(proposal) ||
      JSON.stringify(prevProps.machines) !== JSON.stringify(machines)
    ) {
      this.setState({
        id,
        commercialUserId,
        date: date || new Date(),
        customerId,
        machineId,
        priceSelection,
        discount,
        total,
        deliveryDays,
        opportunityObservations,
        status: status || ProposalStatus.OPORTUNITY,
        proposalObservations,
        submitted: false,
      });
    }

    if (redirectReady !== prevProps.redirectReady && redirectReady) {
      this.setState({ dirty: false }, () => {
        history.push('/dashboard/customers/proposals/list');
      });
    }
  }

  public addCustomer() {
    const { history } = this.props;
    const { id } = this.state;

    localStorage.setItem('proposalToCustomer', JSON.stringify(this.state)); 

    history.push(`/dashboard/customers/new?origin=proposal${id ? '&proposalId=' + id : ''}`);
  }

  public hasErrors() {
    const { commercialUserId, date, customerId, machineId, priceSelection, discount, total } = this.state;

    if (
      !commercialUserId ||
      !date ||
      !customerId ||
      !machineId ||
      !priceSelection ||
      (discount || 0) < 0 ||
      (discount || 0) > 100 ||
      !total
    ) {
      return true;
    }

    return false;
  }

  public handleSubmit() {
    const { createCustomerProposal, editCustomerProposal } = this.props;
    const {
      id,
      commercialUserId,
      date,
      customerId,
      machineId,
      priceSelection,
      discount,
      total,
      opportunityObservations,
      deliveryDays,
      status,
      proposalObservations,
    } = this.state;

    if (this.hasErrors()) {
      return this.setState({ submitted: true });
    }

    if (id) {
      editCustomerProposal({
        id,
        commercialUserId: commercialUserId || 0,
        date,
        customerId: customerId || 0,
        machineId: machineId || 0,
        priceSelection: priceSelection || 'installBuyPrice',
        discount: discount || 0,
        total: total || 0,
        opportunityObservations: opportunityObservations || undefined,
        deliveryDays: deliveryDays || undefined,
        status: status || undefined,
        proposalObservations: proposalObservations || undefined,
      });
    } else {
      createCustomerProposal({
        commercialUserId: commercialUserId || 0,
        date,
        customerId: customerId || 0,
        machineId: machineId || 0,
        priceSelection: priceSelection || 'installBuyPrice',
        discount: discount || 0,
        total: total || 0,
        opportunityObservations: opportunityObservations || undefined,
        deliveryDays: deliveryDays || undefined,
        status: status || undefined,
        proposalObservations: proposalObservations || undefined,
      });
    }
  }

  public handleChange(field: string, event: any) {
    const { machines } = this.props;
    const { machineId } = this.state;

    const stateUpdate = {
      [field]: event.target.value,
      dirty: true,
    };

    if (field === 'priceSelection') {
      const machine = machines.find((m) => m.id === machineId) || ({} as Machine);

      stateUpdate.total =
        event.target.value === 'installBuyPrice'
          ? machine.installBuyPrice || 0
          : event.target.value === 'truckBuyPrice'
          ? machine.truckBuyPrice || 0
          : 0
    }

    this.setState(stateUpdate as Pick<IState, 'dirty'>);
  }

  public handleDateChange(field: string, date: Date) {
    this.setState({
      [field]: date,
      dirty: true,
    } as Pick<IState, 'dirty'>);
  }

  public handleSelect(field: string, value: any) {
    this.setState({ [field]: value, dirty: true } as Pick<IState, 'dirty'>);
  }

  public handleRemove() {
    this.setState({
      machineId: undefined,
      discount: undefined,
      total: undefined,
    });
  }

  public render() {
    const { customers, loading, machines, machineStatus, toggleMachineView, users, viewMachine } = this.props;
    const {
      id,
      commercialUserId,
      date,
      customerId,
      machineId,
      priceSelection,
      discount,
      total,
      opportunityObservations,
      deliveryDays,
      status,

      proposalObservations,
      submitted,
      dirty,
    } = this.state;

    if (!customerId && customers.length === 1) {
      this.handleSelect('customerId', customers[0].id);
    }

    const machine = machines.find((m) => m.id === machineId) || ({} as Machine);
    
    return (
      <div className="dashboard-container">
        <Prompt
          when={dirty}
          message='Existem alterações que não foram gravadas. Tem a certeza que pretende sair do formulário?'
        />

        <Box>
          <Typography variant="h6" component="h2">Clientes</Typography>
          <Breadcrumbs className="breadcrumbs" separator={<FontAwesomeIcon icon={faChevronRight} size="xs" />} aria-label="breadcrumb">
            <Link color="inherit" to="/dashboard/customers/proposals/list">
              Propostas
            </Link>
            <Typography color="textPrimary">{id ? `Editar #${id}` : 'Adicionar'}</Typography>
          </Breadcrumbs>

          <Grid style={{ justifyContent: 'flex-end' }} container>
            <Grid style={{ display: 'flex' }} item>
              <Box mr={1}>
                <Link to="/dashboard/customers/proposals/list">
                  <Button color="default" variant="contained">Cancelar</Button>
                </Link>
              </Box>
              <Button
                disabled={loading}
                variant="contained"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={this.handleSubmit}
              >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
            </Grid>
          </Grid>
        </Box>

        <Box mt={2} pb={2}>
          <Card>
            <CardContent>
              <Box p={2}>
                <Typography className="subtitle" component="b">Oportunidade</Typography>
              </Box>

              <Box m={2}>
                <Grid container spacing={4}>
                  <Grid sm={6} item>
                    <Grid container spacing={2}>
                      <Grid sm={12} item>
                        <FormControl margin="normal" fullWidth>
                          <Autocomplete
                            autoComplete
                            clearOnEscape
                            openOnFocus
                            options={users.filter((u) => u.role === 'commercial' || u.role === 'admincommercial' || u.role === 'admin')}
                            value={users.find((c) => c.id === commercialUserId) || null}
                            getOptionLabel={(option: User) => option.name}
                            onChange={(event, value: any) => this.handleSelect('commercialUserId', value ? value.id : undefined)}
                            renderInput={(params: any) =>
                              <TextField
                                {...params}
                                label="Comercial responsável"
                                required
                                error={submitted && !commercialUserId}
                                helperText={submitted && !commercialUserId ? 'Deve seleccionar um comercial responsável' : ''}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FontAwesomeIcon className="form-icon" icon={faUser} />
                                    </InputAdornment>
                                  )
                                }}
                              />
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid sm={12} item>
                        <FormControl margin="normal" fullWidth>
                          <DatePicker
                            error={submitted && !date}
                            disableToolbar
                            variant="inline"
                            format="yyyy/MM/dd"
                            required
                            label="Data da oportunidade"
                            value={date}
                            onChange={(date: any) => this.handleDateChange('date', date)}
                            helperText={submitted && !date ? 'Deve introduzir a data da oportunidade' : ''}
                          />
                        </FormControl>
                      </Grid>
                      <Grid sm={12} item>
                        <FormControl margin="normal" fullWidth>
                          <Autocomplete
                            autoComplete
                            clearOnEscape
                            openOnFocus
                            options={machines.filter((c) =>
                              // Only show machines with status Em stock & Em intermediação
                              machineStatus.filter((s) =>
                                s.value.toLowerCase().indexOf('stock') !== -1 || s.value.toLowerCase().indexOf('intermedia') !== -1
                              ).map((s) => s.id).indexOf(c.status) !== -1 || c.id === machineId
                            )}
                            value={machines.find((c) => c.id === machineId) || null}
                            getOptionLabel={(option) => `#${option.id} ${option.brand || ''} ${option.model || ''} ${option.description ? `(${option.description})` : ''}`}
                            onChange={(event, value: any) => this.handleSelect('machineId', value ? value.id : null)}
                            renderInput={(params: any) =>
                              <TextField
                                {...params}
                                label="Máquina"
                                required
                                error={submitted && !machineId}
                                helperText={submitted && !machineId ? 'Deve seleccionar uma máquina' : ''}
                              />
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid sm={6} item>
                    <Grid container spacing={4}>
                      <Grid sm={12} item>
                        <FormControl margin="normal" fullWidth>
                          <Autocomplete
                            autoComplete
                            clearOnEscape
                            openOnFocus
                            options={customers}
                            value={customers.find((c) => c.id === customerId) || null}
                            getOptionLabel={(option: Customer) => option.name}
                            onChange={(event, value: any) => this.handleSelect('customerId', value ? value.id : undefined)}
                            renderOption={(option) => {
                              const customer = customers.find((c) => c.id === option.id) || {} as Customer;

                              return (
                                <Typography variant="body1">
                                  {option.name}
                                  {!customer.active ? <Typography variant="caption" style={{ color: '#ccc', marginLeft: '7px' }}>(Cliente inactivo)</Typography> : null}
                                </Typography>
                              );
                            }}
                            renderInput={(params: any) =>
                              <TextField
                                {...params}
                                label="Cliente"
                                required
                                error={submitted && !customerId}
                                helperText={submitted && !customerId ? 'Deve seleccionar um cliente' : ''}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FontAwesomeIcon className="form-icon" icon={faUserTag} />
                                    </InputAdornment>
                                  )
                                }}
                              />
                            }
                          />
                        </FormControl>
                        <CardActions style={{ flexDirection: 'row-reverse' }}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<AddIcon />}
                            onClick={this.addCustomer}
                          >
                            Adicionar Cliente
                          </Button>
                        </CardActions>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid sm={12} item>
                    <FormControl fullWidth>
                      <TextField
                        label="Observações"
                        value={opportunityObservations || ''}
                        variant="standard"
                        multiline={true}
                        minRows={3}
                        inputProps={{ maxLength: 3000 }}
                        onChange={(event: any) => this.handleChange('opportunityObservations', event)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box mt={2} pb={2}>
          <Box mb={2}>
            <Card>
              <CardContent>
                <Box p={2}>
                  <Typography className="subtitle" component="b">Proposta</Typography>
                </Box>

                <Box m={2}>
                  <Grid container spacing={4}>
                    <Grid sm={6} item>
                      <Grid container spacing={2}>
                        <Grid sm={12} item>
                          <FormControl margin="dense" fullWidth>
                            <InputLabel id="proposals-status">Estado</InputLabel>
                            <Select
                              labelId="proposals-status"
                              value={status || ''}
                              style={{ height: '32px' }}
                              onChange={(event: any) => this.handleChange('status', event)}
                            >
                              <MenuItem value="oportunity">Oportunidade</MenuItem>
                              <MenuItem value="pending">Pendente</MenuItem>
                              <MenuItem value="awarded">Adjudicada</MenuItem>
                              <MenuItem value="notawarded">Não Ajudicada</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid sm={6} item>
                      <Grid container spacing={2}>
                        <Grid sm={12} item>
                          <FormControl margin="dense" fullWidth>
                            <TextField
                              label="Prazo de entrega"
                              value={deliveryDays || ''}
                              variant="standard"
                              type="number"
                              inputProps={{ min: 0 }}
                              InputProps={{ endAdornment: <InputAdornment position="end">dias</InputAdornment> }}
                              onChange={(event: any) => this.handleChange('deliveryDays', event)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid sm={12} item>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Fotografia</TableCell>
                              <TableCell>Ref. Máquina</TableCell>
                              <TableCell>Modelo</TableCell>
                              <TableCell>Tipo de preço</TableCell>
                              <TableCell>Preço total</TableCell>
                              <TableCell>Desconto</TableCell>
                              <TableCell>Acções</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {machineId ? (
                              <TableRow>
                                <TableCell>
                                  {getCoverImage(machine.files) !== undefined ? (
                                    <img
                                      alt={String(machine.id || '')}
                                      src={`${process.env.REACT_APP_API_URL}/api/files/machines/${getCoverImage(machine.files)!.filename}`}
                                      style={{ maxWidth: '96px', maxHeight: '72px'}}
                                    />
                                  ) : null}
                                </TableCell>
                                <TableCell>
                                  <Button color="primary" onClick={() => toggleMachineView(machine.id)} variant="outlined" size="small">#{machine.id}</Button>
                                </TableCell>
                                <TableCell>{machine.model}</TableCell>
                                <TableCell>
                                  <FormControl margin="normal" error={submitted && !priceSelection} required fullWidth style={{ top: '-10px' }}>
                                    <InputLabel id="proposals-priceselection">Tipo de preço</InputLabel>
                                    <Select
                                      id="proposals-priceselection"
                                      value={priceSelection || ''}
                                      onChange={(event: any) => this.handleChange('priceSelection', event)}
                                      name="priceSelection"
                                      style={{ minWidth: '220px' }}
                                    >
                                      <MenuItem value="" disabled>
                                        <i>Seleccione o preço</i>
                                      </MenuItem>
                                      <MenuItem value="installBuyPrice">Preço com instalação</MenuItem>
                                      <MenuItem value="truckBuyPrice">Preço em cima do camião</MenuItem>
                                    </Select>
                                    {submitted && !priceSelection ? <FormHelperText>Deve inserir o preço em cima do camião</FormHelperText> : null}
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <FormControl margin="normal" fullWidth style={{ top: '-10px' }}>
                                    <TextField
                                      error={submitted && !(total !== 0 ? total || '' : '')}
                                      label="Valor"
                                      value={total || ''}
                                      variant="standard"
                                      type="number"
                                      required
                                      inputProps={{ step: 0.01 }}                  
                                      InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                                      onChange={(event: any) => this.handleChange('total', event)}
                                      helperText={submitted && !(total !== 0 ? total || '' : '') ? 'Deve inserir o total' : ''}
                                    />
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <FormControl margin="normal" fullWidth style={{ top: '-10px' }}>
                                    <TextField
                                      error={submitted && (((discount || 0) < 0) || ((discount || 0) > 100))}
                                      label="Desconto"
                                      value={discount || ''}
                                      variant="standard"
                                      type="number"
                                      inputProps={{ step: 0.01, min: 0, max: 100 }}                  
                                      InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                                      onChange={(event: any) => this.handleChange('discount', event)}
                                      helperText={submitted && (((discount || 0) < 0) || ((discount || 0) > 100)) ? 'Deve inserir um desconto válido' : ''}
                                    />
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <TableButton onClick={this.handleRemove}>
                                    <FontAwesomeIcon icon={faTimes} />
                                  </TableButton>
                                </TableCell>
                              </TableRow>
                            ) : null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid sm={12} item>
                      <FormControl fullWidth>
                        <TextField
                          label="Observações"
                          value={proposalObservations || ''}
                          variant="standard"
                          multiline={true}
                          minRows={3}
                          inputProps={{ maxLength: 3000 }}
                          onChange={(event: any) => this.handleChange('proposalObservations', event)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Box>

          <Grid style={{ justifyContent: 'flex-end' }} container>
            <Grid style={{ display: 'flex' }} item>
              <Box mr={1}>
                <Link to="/dashboard/customers/proposals/list">
                  <Button color="default" variant="contained">Cancelar</Button>
                </Link>
              </Box>
              <Button
                disabled={loading}
                variant="contained"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={this.handleSubmit}
              >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
            </Grid>
          </Grid>
        </Box>
        {viewMachine ? <ViewModal /> : null}
      </div>
    );
  }
};

export default Form;
