import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlug } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import className from 'classname';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { DynamicValue } from '../../../../models/DynamicField';
import Machine from '../../../../models/Machine';
import Supplier from '../../../../models/Supplier';
import Button from '../../../common/Button';
import { steps } from '../Form/Form';
import { MachineTypes, machineTypes } from '../sources';
import Info from './Info';
import Details from './Details';
import Turreta from './Turreta';
import Fuses from './Fuses';
import BarSupplier from './BarSupplier';
import ErosionHead from './ErosionHead';
import Generator from './Generator';
import InjectionUnit from './InjectionUnit';
import Logistics from './Logistics';
import Optionals from './Optionals';
import User from '../../../../models/User';

interface IProps {
  loading: boolean;
  fetchSuppliers: () => void;
  machine: Machine;
  machineStatus: DynamicValue[];
  suppliers: Supplier[];
  toggleMachineView: (machineId?: number) => void;
  user: User;
}

interface IState {
  step: string;
}

class ViewModal extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      step: 'info',
    };

    this.changeStep = this.changeStep.bind(this);
  }

  public componentDidMount() {
    const { fetchSuppliers } = this.props;

    fetchSuppliers();
  }

  public changeStep(step: string) {
    this.setState({ step });
  }

  public render() {
    const { loading, machine, machineStatus, suppliers, toggleMachineView, user } = this.props;
    const { step } = this.state;
    const { id, type } = machine;
    const activeStep = steps.filter((s) => s.present.indexOf(machine.type as MachineTypes) !== -1).findIndex((s) => s.id === step);

    return (
      <Dialog open fullWidth maxWidth="lg" onClose={() => toggleMachineView()}>
        <DialogTitle>
          <FontAwesomeIcon className="header-icon" icon={faPlug} />
          <span style={{ marginLeft: '8px' }}>Detalhes da máquina - {id}</span>
          <IconButton edge="end" size="small" className="dialog-close-button" onClick={() => toggleMachineView()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: '#eef1f5' }}>
          {loading
            ? <CircularProgress size={24} />
            : <>
              <Card>
                <CardContent>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Tipo de Máquina</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{(machineTypes.find((mt) => mt.id === type) || {}).label}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Box pt={2}>
                <Stepper alternativeLabel activeStep={activeStep} style={{ backgroundColor: 'transparent' }}>
                  {steps.filter((s) => s.present.indexOf(machine.type as MachineTypes) !== -1).map((s) => (
                    <Step key={s.id} onClick={() => this.changeStep(s.id)}>
                      <StepLabel style={{ cursor: 'pointer' }} StepIconComponent={(props: { active: boolean; completed: boolean }) => {
                        const iconClass = className('stepper-icon', {
                          active: !!props.active,
                          completed: !!props.completed
                        });

                        return (
                          <div
                            className={iconClass}
                            onClick={props.completed ? () => this.changeStep(s.id) : undefined}
                          >{s.icon}</div>
                        );
                      }}>{s.label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            
              {step === 'info' ? <Info suppliers={suppliers} machine={machine} machineStatus={machineStatus} user={user} /> : null}
              {step === 'details' ? <Details machine={machine} /> : null}
              {step === 'turreta' ? <Turreta machine={machine} /> : null}
              {step === 'fuses' ? <Fuses machine={machine} /> : null}
              {step === 'barsupplier' ? <BarSupplier machine={machine} /> : null}
              {step === 'erosionhead' ? <ErosionHead machine={machine} /> : null}
              {step === 'generator' ? <Generator machine={machine} /> : null}
              {step === 'injectionunit' ? <InjectionUnit machine={machine} /> : null}
              {step === 'logistics' ? <Logistics machine={machine} /> : null}
              {step === 'optionals' ? <Optionals machine={machine} /> : null}
            </>
          }
        </DialogContent>
        <Box p={2} pt={0}>
          <DialogActions>
            {user.role !== 'admintech' && user.role !== 'tech' && user.role !== 'externaltech' && user.role !== 'commercial' && (
              <Box mr={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  startIcon={<FontAwesomeIcon icon={faPencilAlt} />}
                  component={Link}
                  to={`/dashboard/machines/machineid/${id}/edit`}
                >Editar</Button>
              </Box>
            )}
            <Button color="default" variant="contained" onClick={() => toggleMachineView()}>Fechar</Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
};

export default ViewModal;