import { createAction, createAsyncAction } from 'typesafe-actions';

import APIFile from '../models/File';
import Machine, { MachineWithProposal } from '../models/Machine';
import Ticket, { TicketMeta } from '../models/Ticket';
import Task, { TaskStatus, TaskExpense, TaskComment, TaskExpenseType, TaskSign } from '../models/Task';
import { IFailed } from './types';

enum Types {
  FETCH = '@@machines/FETCH',
  FETCH_FAILED = '@@machines/FETCH_FAILED',
  FETCH_SUCCEEDED = '@@machines/FETCH_SUCCEEDED',

  CREATE = '@@machines/CREATE',
  CREATE_FAILED = '@@machines/CREATE_FAILED',
  CREATE_SUCCEEDED = '@@machines/CREATE_SUCCEEDED',

  EDIT = '@@machines/EDIT',
  EDIT_FAILED = '@@machines/EDIT_FAILED',
  EDIT_SUCCEEDED = '@@machines/EDIT_SUCCEEDED',

  OPEN_DELETE_MACHINE = '@@machines/OPEN_DELETE_MACHINE',
  CLOSE_DELETE_MACHINE = '@@machines/CLOSE_DELETE_MACHINE',

  DELETE = '@@machines/DELETE',
  DELETE_FAILED = '@@machines/DELETE_FAILED',
  DELETE_SUCCEEDED = '@@machines/DELETE_SUCCEEDED',

  CHANGE_MACHINE_CUSTOMER = '@@machines/CHANGE_MACHINE_CUSTOMER',
  CHANGE_MACHINE_CUSTOMER_FAILED = '@@machines/CHANGE_MACHINE_CUSTOMER_FAILED',
  CHANGE_MACHINE_CUSTOMER_SUCCEEDED = '@@machines/CHANGE_MACHINE_CUSTOMER_SUCCEEDED',

  TOGGLE_VIEW = '@@machines/TOGGLE_VIEW',
  TOGGLE_CUSTOMER_FORM = '@@machines/TOGGLE_CUSTOMER_FORM',

  FETCH_MACHINES_TICKETS = '@@machines/FETCH_MACHINES_TICKETS',
  FETCH_MACHINES_TICKETS_FAILED = '@@machines/FETCH_MACHINES_TICKETS_FAILED',
  FETCH_MACHINES_TICKETS_SUCCEEDED = '@@machines/FETCH_MACHINES_TICKETS_SUCCEEDED',

  CREATE_MACHINE_TICKET = '@@machines/CREATE_MACHINE_TICKET',
  CREATE_MACHINE_TICKET_FAILED = '@@machines/CREATE_MACHINE_TICKET_FAILED',
  CREATE_MACHINE_TICKET_SUCCEEDED = '@@machines/CREATE_MACHINE_TICKET_SUCCEEDED',

  EDIT_MACHINE_TICKET = '@@machines/EDIT_MACHINE_TICKET',
  EDIT_MACHINE_TICKET_FAILED = '@@machines/EDIT_MACHINE_TICKET_FAILED',
  EDIT_MACHINE_TICKET_SUCCEEDED = '@@machines/EDIT_MACHINE_TICKET_SUCCEEDED',

  DELETE_MACHINE_TICKET_CONFIRM = '@@machines/DELETE_MACHINE_TICKET_CONFIRM',

  DELETE_MACHINE_TICKET = '@@machines/DELETE_MACHINE_TICKET',
  DELETE_MACHINE_TICKET_FAILED = '@@machines/DELETE_MACHINE_TICKET_FAILED',
  DELETE_MACHINE_TICKET_SUCCEEDED = '@@machines/DELETE_MACHINE_TICKET_SUCCEEDED',

  CREATE_MACHINE_TICKET_TASK = '@@machines/CREATE_MACHINE_TICKET_TASK',
  CREATE_MACHINE_TICKET_TASK_FAILED = '@@machines/CREATE_MACHINE_TICKET_TASK_FAILED',
  CREATE_MACHINE_TICKET_TASK_SUCCEEDED = '@@machines/CREATE_MACHINE_TICKET_TASK_SUCCEEDED',

  EDIT_MACHINE_TICKET_TASK = '@@machines/EDIT_MACHINE_TICKET_TASK',
  EDIT_MACHINE_TICKET_TASK_FAILED = '@@machines/EDIT_MACHINE_TICKET_TASK_FAILED',
  EDIT_MACHINE_TICKET_TASK_SUCCEEDED = '@@machines/EDIT_MACHINE_TICKET_TASK_SUCCEEDED',

  DELETE_MACHINE_TICKET_TASK = '@@machines/DELETE_MACHINE_TICKET_TASK',
  DELETE_MACHINE_TICKET_TASK_FAILED = '@@machines/DELETE_MACHINE_TICKET_TASK_FAILED',
  DELETE_MACHINE_TICKET_TASK_SUCCEEDED = '@@machines/DELETE_MACHINE_TICKET_TASK_SUCCEEDED',

  CREATE_MACHINE_TICKET_TASK_EXPENSE = '@@machines/CREATE_MACHINE_TICKET_TASK_EXPENSE',
  CREATE_MACHINE_TICKET_TASK_EXPENSE_FAILED = '@@machines/CREATE_MACHINE_TICKET_TASK_EXPENSE_FAILED',
  CREATE_MACHINE_TICKET_TASK_EXPENSE_SUCCEEDED = '@@machines/CREATE_MACHINE_TICKET_TASK_EXPENSE_SUCCEEDED',

  DELETE_MACHINE_TICKET_TASK_EXPENSE = '@@machines/DELETE_MACHINE_TICKET_TASK_EXPENSE',
  DELETE_MACHINE_TICKET_TASK_EXPENSE_FAILED = '@@machines/DELETE_MACHINE_TICKET_TASK_EXPENSE_FAILED',
  DELETE_MACHINE_TICKET_TASK_EXPENSE_SUCCEEDED = '@@machines/DELETE_MACHINE_TICKET_TASK_EXPENSE_SUCCEEDED',

  CREATE_MACHINE_TICKET_TASK_COMMENT = '@@machines/CREATE_MACHINE_TICKET_TASK_COMMENT',
  CREATE_MACHINE_TICKET_TASK_COMMENT_FAILED = '@@machines/CREATE_MACHINE_TICKET_TASK_COMMENT_FAILED',
  CREATE_MACHINE_TICKET_TASK_COMMENT_SUCCEEDED = '@@machines/CREATE_MACHINE_TICKET_TASK_COMMENT_SUCCEEDED',

  DELETE_MACHINE_TICKET_TASK_COMMENT = '@@machines/DELETE_MACHINE_TICKET_TASK_COMMENT',
  DELETE_MACHINE_TICKET_TASK_COMMENT_FAILED = '@@machines/DELETE_MACHINE_TICKET_TASK_COMMENT_FAILED',
  DELETE_MACHINE_TICKET_TASK_COMMENT_SUCCEEDED = '@@machines/DELETE_MACHINE_TICKET_TASK_COMMENT_SUCCEEDED',

  CREATE_MACHINE_TICKET_TASK_SIGN = '@@machines/CREATE_MACHINE_TICKET_TASK_SIGN',
  CREATE_MACHINE_TICKET_TASK_SIGN_FAILED = '@@machines/CREATE_MACHINE_TICKET_TASK_SIGN_FAILED',
  CREATE_MACHINE_TICKET_TASK_SIGN_SUCCEEDED = '@@machines/CREATE_MACHINE_TICKET_TASK_SIGN_SUCCEEDED',

  CREATE_MACHINE_TICKET_VIEW = '@@machines/CREATE_MACHINE_TICKET_VIEW',

  OPEN_SIGN = '@@machines/OPEN_SIGN',
  CLOSE_SIGN = '@@machines/CLOSE_SIGN',

  OPEN_MACHINE_TICKET_TASK = '@@machines/OPEN_MACHINE_TICKET_TASK',
  CLOSE_MACHINE_TICKET_TASK = '@@machines/CLOSE_MACHINE_TICKET_TASK',

  OPEN_MACHINE_TICKET_TASK_MULTISIGN = '@@machines/OPEN_MACHINE_TICKET_TASK_MULTISIGN',
  CLOSE_MACHINE_TICKET_TASK_MULTISIGN = '@@machines/CLOSE_MACHINE_TICKET_TASK_MULTISIGN',

  FETCH_MARKETPLACE_MACHINES = '@@machines/FETCH_MARKETPLACE_MACHINES',
  FETCH_MARKETPLACE_MACHINES_FAILED = '@@machines/FETCH_MARKETPLACE_MACHINES_FAILED',
  FETCH_MARKETPLACE_MACHINES_SUCCEEDED = '@@machines/FETCH_MARKETPLACE_MACHINES_SUCCEEDED',

  OPEN_MARKETPLACE_FORM = '@@machines/OPEN_MARKETPLACE_FORM',
  CLOSE_MARKETPLACE_FORM = '@@machines/CLOSE_MARKETPLACE_FORM',

  SEND_MACHINE_CONTACT = '@@machines/SEND_MACHINE_CONTACT',
  SEND_MACHINE_CONTACT_FAILED = '@@machines/SEND_MACHINE_CONTACT_FAILED',
  SEND_MACHINE_CONTACT_SUCCEEDED = '@@machines/SEND_MACHINE_CONTACT_SUCCEEDED',

  FETCH_DASHBOARD_TASKS = '@@machines/FETCH_DASHBOARD_TASKS',
  FETCH_DASHBOARD_TASKS_FAILED = '@@machines/FETCH_DASHBOARD_TASKS_FAILED',
  FETCH_DASHBOARD_TASKS_SUCCEEDED = '@@machines/FETCH_DASHBOARD_TASKS_SUCCEEDED',
}

interface FetchSuccess {
  machines: MachineWithProposal[];
}

export const fetchMachines = createAsyncAction(
  Types.FETCH,
  Types.FETCH_SUCCEEDED,
  Types.FETCH_FAILED
)<void, FetchSuccess, IFailed>();

export interface CreateMachine {
  type: string;

  // Info Step
  reference?: string;
  description?: string;
  supplierId?: number;
  customerId?: number;
  brand?: string;
  model?: string;
  serialnumber?: string;
  commandType?: string;
  commandBrand?: string;
  commandModel?: string;
  isMachineNew?: boolean;
  buyPrice?: number;
  installBuyPrice?: number;
  truckBuyPrice?: number;
  machineYear?: number;
  machineUsageHours?: number;
  status: number;
  costsOthersMaterial?: number;
  costsOthersTravels?: number;
  costsOthersManHours?: number;
  costsOthersOthers?: number;

  // Details Step
  courseX?: number;
  courseY?: number;
  courseZ?: number;
  course4Axis?: number;
  course4AxisMM?: number;
  course5Axis?: number;
  axisType?: string;
  positioning4Axis?: string;
  positioning5Axis?: string;
  autoPalletExchange?: boolean;
  autoPalletExchangeQuantity?: number;
  autoPalletExchangeDimension?: number;
  tableDimensionX?: number;
  tableDimensionY?: number;
  tableDimensionDiameter?: number;
  spindleTableDistance?: number;
  spindleTableDistanceMin?: number;
  spindleTableDistanceMax?: number;
  spindleTableWeight?: number;
  rotativeTable?: boolean;
  rotativeTableAngleMinPositioning?: number;
  robot?: boolean;
  robotNumberOfEletrods?: number;
  robotNumberOfPallets?: number;
  robotNumberOfAxis?: number;
  robotReach?: number;
  tankDimensionLength?: number;
  tankDimensionWidth?: number;
  tankDimensionHeight?: number;
  tankSubmersible?: boolean;
  tankSubmersibleAuto?: boolean;
  portableSteeringWheel?: boolean;
  partMaxHeight?: number;
  dielectricQuantity?: number;
  hasCorePullers?: boolean;
  corePullersSideMobile?: boolean;
  corePullersSideFixed?: boolean;
  corePullersQuantity?: string;
  hasPneumaticValves?: boolean;
  pneumaticValvesSideMobile?: boolean;
  pneumaticValvesSideFixed?: boolean;
  pneumaticValvesQuantity?: string;
  hasControlValveGate?: boolean;
  controlValveGateQuantity?: number;
  hasMoldTemperatureControl?: boolean;
  moldTemperatureControlZones?: number;
  maxHydricPression?: number;
  heatingPower?: number;
  oilTankCapacity?: number;
  motorPump?: number;
  motorPower?: number;
  tempControlZones?: number;
  magneticTableDimensionLength?: number;
  magneticTableDimensionWidth?: number;
  magneticTableWeight?: number;
  rapidAxisVelocityX?: number;
  rapidAxisVelocityY?: number;
  rapidAxisVelocityZ?: number;
  programmableVelocityAxisX?: number;
  programmableVelocityAxisY?: number;
  programmableVelocityAxisZ?: number;
  rapidAxisVelocityC?: number;
  programmableVelocityAxisC?: number;
  rapidAxisVelocityB?: number;
  programmableVelocityAxisB?: number;
  transmissionMotor4Axis?: string;
  transmissionMotor5Axis?: string;
  cutMaxVelocity?: number;
  cutMaxAngle?: number;
  cutMaxAngleByLength?: number;
  turningDiameter?: number;
  turningLength?: number;
  detailsRPM?: number;
  detailsPotence?: number;
  detailsTorque?: number;
  detailsConeType?: number;
  headtype?: string;
  guidesWidth?: number;
  RPMAxisC?: number;
  maximumFeedrate?: number;
  connectionPower?: number;

  // Fuses Step
  tornTurret?: boolean;
  toolQuantity?: number;
  warehouseType?: number;
  tollExchangeTime?: number;
  supportType?: string;
  staticSupportNumber?: number;
  motorTool?: boolean;
  motorToolRPM?: number;
  motorToolPotence?: number;
  motorToolSupports?: string;
  motorToolNew?: boolean;
  motorToolNewSupportName?: string;
  motorToolNewSupportDescription?: string;
  motorToolNewSupportBuyPrice?: number;
  motorToolNewSupportSellPrice?: number;
  motorToolRadQuantity?: number;
  motorToolAxiQuantity?: number;
  electrodeWarehouse?: boolean;
  electrodeWarehouseQuantity?: number;
  electrodeMaxHeight?: number;
  electrodeMaxErosionHead?: number;

  // Turreta Step
  highPressureInteriorRefrigeration?: boolean;
  highPressureInteriorRefrigerationBar?: number;
  wasteExtractor?: boolean;
  wasteExtractorType?: string;
  toolMeasurer?: boolean;
  toolMeasurerType?: string;
  autoCenter?: boolean;
  autoCenterBrand?: string;
  cabinada?: boolean;
  partCatcher?: boolean;
  toolMeasurerArm?: boolean;
  toolMeasurerArmAutomatic?: string;
  steeringWheelElec?: boolean;
  tornoDiameterTurnable?: number;
  tornoDiameterBush?: number;
  tornoDiameterCar?: number;
  tornoDiameterBarPass?: number;
  spindleRPM?: number;
  spindleMotorPotence?: number;
  spindleDiameter?: number;
  spindleNoseType?: string;
  spindleLiveTools?: boolean;
  spindleLiveToolsQuantity?: number;
  spindleLiveToolsRPM?: number;
  torque?: number;
  coneType?: number;
  spindleType?: string;
  spindleElectro?: boolean;
  spindleElectroBrand?: string;
  spindleElectroCoolerType?: number;
  spindleInterfaceType?: string;
  subSpindle?: boolean;
  subSpindleDiameterTornable?: number;
  subSpindleDiameterBush?: number;
  subSpindleDiameterCar?: number;
  subSpindleDiameterBar?: number;
  subSpindleNoseType?: string;
  subSpindleLiveTools?: boolean;
  subSpindleLiveToolsQuantity?: number;
  subSpindleLiveToolsRPM?: number;
  subSpindleRPM?: number;
  subSpindleMoterPower?: number;
  subSpindleInterfaceType?: string;
  subSpindlePartCatcher?: boolean;
  subSpindleTravelAxisLength?: number;
  contraPonto?: boolean;
  contraPontoType?: string;
  contraPontoPath?: string;
  contraPontoProgrammablePositioning?: boolean;
  grindDimensionX?: number;
  grindDimensionY?: number;
  grindDimensionZ?: number;
  grindTableDistance?: number;
  grindMinVerticalAdd?: number;
  grindRotation?: number;
  gridPotence?: number;
  gridRPM?: number;
  gridMotorPotence?: number;
  gridRectifierAuto?: boolean;
  gridBalanceSystem?: boolean;
  gridFilteringSystem?: boolean;
  gridFilteringSystemVacuum?: boolean;
  gridFilteringSystemPaper?: boolean;
  gridFilteringSystemMagnetic?: boolean;
  gridFilteringSystemOther?: boolean;
  gridFilteringSystemOtherValue?: string;
  gridRectifierMaxDiameter?: number;
  gridCentersHeight?: number;
  gridCentersInternalRetifierDiameterMin?: number;
  gridCentersInternalRetifierDiameterMax?: number;
  gridRetifierAngleMin?: number;
  gridRetifierAngleMax?: number;
  gridMaxPartWeight?: number;
  gridCarCourse?: number;
  gridAutoPassing?: boolean;
  gridCenterHeadType?: string;
  gridCenterContraPontoType?: string;
  gridCenterContraPontoPath?: number;
  gridBalancerIncluded?: boolean;
  closeUnitPlateDimensionH?: number;
  closeUnitPlateDimensionV?: number;
  closeUnitColumnDistanceH?: number;
  closeUnitColumnDistanceV?: number;
  closeUnitMoldTicknessMin?: number;
  closeUnitMoldTicknessMax?: number;
  closeUnitMinMoldDimension?: string;
  closeUnitForce?: number;
  openUnitForce?: number;
  closeUnitSupPlateCourse?: number;
  closeUnitSupPlateMove?: boolean;
  closeUnitMobilePlateCourse?: number;
  closeUnitMaxDistanceBetween?: number;
  closeUnitExtractorQuantity?: number;
  closeUnitExtractorForce?: number;
  closeUnitExtractionCourse?: number;

  // Bar supplier Step
  barSupplierBrand?: string;
  barSupplierModel?: string;
  barSupplierMaxDiameter?: number;
  barSupplierMaxHeight?: number;
  barSupplierDimentionC?: number;
  barSupplierDimentionL?: number;
  barSupplierDimentionA?: number;
  barSupplierWeight?: number;
  
  // Erosion Step
  erosionHeadAxisC360?: boolean;
  erosionHeadType?: string;
  erosionHeadSubType?: string;
  erosionThreadMin?: number;
  erosionThreadMax?: number;
  erosionThreadingAuto?: boolean;
  erosionRodMin?: number;
  erosionRodMax?: number;
  erosionRodAuto?: boolean;
  sawType?: string;
  sawVelocityVariation?: boolean;
  sawVelocityVariationIMin?: number;
  sawVelocityVariationIMax?: number;
  sawAutoSupply?: boolean;
  sawCutDimensionsMinDiameter?: number;
  sawCutDimensionsMinRectangle?: number;
  sawCutDimensionsMaxDiameter?: number;
  sawCutDimensionsMaxRectangle?: number;
  sawCutMinLength?: number;
  sawCutMaxLength?: number;
  sawAngleCut?: boolean;
  sawAngleCutMin?: number;
  sawAngleCutMax?: number;
  sawTapeDimensionsC?: number;
  sawTapeDimensionsL?: number;
  sawTapeDimensionsA?: number;
  sawDimensions?: number;
  sawWeight?: number;

  // Generator Step
  generatorHasRefrigerator?: boolean;
  generatorAMP?: number;

  // Injection Unit
  injectionUnitFuseDiameter?: number;
  injectionUnitMaxRPMFuse?: number;
  injectionUnitVolume?: number;
  injectionUnitMaxWeight?: number;
  injectionUnitPression?: number;
  injectionUnitRatio?: number;
  injectionCenterRingDiameter?: number;
  injectionScrewStrock?: number;
  injectionRate?: number;
  injectionNozzleContactForce?: number;
  injectionNozzleStroke?: number;
  injectionResineSystem?: boolean;
  ndInjectionUnit?: boolean;
  ndInjectionUnitFuseDiameter?: number;
  ndInjectionUnitMaxRPMFuse?: number;
  ndInjectionUnitVolume?: number;
  ndInjectionUnitMaxWeight?: number;
  ndInjectionUnitPression?: number;
  ndInjectionUnitRatio?: number;
  ndInjectionCenterRingDiameter?: number;
  ndInjectionScrewStrock?: number;
  ndInjectionRate?: number;
  ndInjectionNozzleContactForce?: number;
  ndInjectionNozzleStroke?: number;
  ndInjectionResineSystem?: boolean;
  pressDimensionLength?: number;
  pressDimensionWidth?: number;
  pressDimensionHeight?: number;
  pressWeight?: number;

  // Logistics Step
  dimensionC?: number;
  dimensionL?: number;
  dimensionA?: number;
  weight?: number;
  deliveryDate?: string;
  warranty?: string;
  transportPrice?: number;
  paymentConditions?: string;
  commands?: string;

  // Optionals Step
  observations?: string;
  youtubeLink?: string;
  catalog?: string;
  catalogOriginalname?: string;
  catalogFilename?: string;
  files: File[];
  marketplaceVisibility?: boolean;
  marketplaceEmphasis?: boolean;
  marketplacePriceVisibillity?: boolean;
  marketplacePriceType?: 'installPrice' | 'truckPrice';
}

interface MachineSuccess {
  machine: Machine;
}

export const createMachine = createAsyncAction(
  Types.CREATE,
  Types.CREATE_SUCCEEDED,
  Types.CREATE_FAILED
)<CreateMachine, MachineSuccess, IFailed>();

export interface EditMachine extends CreateMachine {
  id: number,
}

export const editMachine = createAsyncAction(
  Types.EDIT,
  Types.EDIT_SUCCEEDED,
  Types.EDIT_FAILED
)<EditMachine, MachineSuccess, IFailed>();

export interface DeleteMachine {
  machineId: number;
}

export const deleteMachine = createAsyncAction(
  Types.DELETE,
  Types.DELETE_SUCCEEDED,
  Types.DELETE_FAILED
)<DeleteMachine, DeleteMachine, IFailed>();

export const openDeleteMachine = createAction(Types.OPEN_DELETE_MACHINE)<number>();
export const closeDeleteMachine = createAction(Types.CLOSE_DELETE_MACHINE)<void>();

interface FetchTicketsSuccess {
  tickets: Ticket[];
}

export const toggleMachineView = createAction(
  Types.TOGGLE_VIEW,
)<number | undefined>();

export interface ChangeMachineCustomer {
  id: number;
  customerId: number | undefined;
}
export const changeMachineCustomer = createAsyncAction(
  Types.CHANGE_MACHINE_CUSTOMER,
  Types.CHANGE_MACHINE_CUSTOMER_SUCCEEDED,
  Types.CHANGE_MACHINE_CUSTOMER_FAILED
)<ChangeMachineCustomer, ChangeMachineCustomer, IFailed>();

export const toggleMachineCustomerForm = createAction(
  Types.TOGGLE_CUSTOMER_FORM,
)<Machine | undefined>();

export const fetchMachinesTickets = createAsyncAction(
  Types.FETCH_MACHINES_TICKETS,
  Types.FETCH_MACHINES_TICKETS_SUCCEEDED,
  Types.FETCH_MACHINES_TICKETS_FAILED
)<void, FetchTicketsSuccess, IFailed>();

export interface CreateMachineTicket {
  observations?: string;
  title: string;
  machineId?: number;
  customerId: number;
  limitDate?: Date;
  responsibleUserId: number;
  zoneId: number;
  costCenter?: string;
  invoiceno?: string;
}

interface TicketSuccess {
  ticket: TicketMeta;
}

export const createMachineTicket = createAsyncAction(
  Types.CREATE_MACHINE_TICKET,
  Types.CREATE_MACHINE_TICKET_SUCCEEDED,
  Types.CREATE_MACHINE_TICKET_FAILED
)<CreateMachineTicket, TicketSuccess, IFailed>();

export interface EditMachineTicket extends CreateMachineTicket {
  id: number;
  created: Date;
}

export const editMachineTicket = createAsyncAction(
  Types.EDIT_MACHINE_TICKET,
  Types.EDIT_MACHINE_TICKET_SUCCEEDED,
  Types.EDIT_MACHINE_TICKET_FAILED
)<EditMachineTicket, TicketSuccess, IFailed>();

export const showDeleteMachineTicket = createAction(
  Types.DELETE_MACHINE_TICKET_CONFIRM,
)<number | undefined>();

export interface DeleteMachineTicket {
  id: number;
}
interface DeleteMachineSuccess extends DeleteMachineTicket {};
export const deleteMachineTicket = createAsyncAction(
  Types.DELETE_MACHINE_TICKET,
  Types.DELETE_MACHINE_TICKET_SUCCEEDED,
  Types.DELETE_MACHINE_TICKET_FAILED
)<DeleteMachineTicket, DeleteMachineSuccess, IFailed>();

interface TicketTaskSuccess {
  task: Task;
}

interface TicketTaskFailed extends IFailed {
  status: TaskStatus;
}

export interface CreateMachineTicketTask {
  ticketid: number;
  title: string;
  technicians: number[];
  status: TaskStatus;
  files: File[];
}

export const createMachineTicketTask = createAsyncAction(
  Types.CREATE_MACHINE_TICKET_TASK,
  Types.CREATE_MACHINE_TICKET_TASK_SUCCEEDED,
  Types.CREATE_MACHINE_TICKET_TASK_FAILED
)<CreateMachineTicketTask, TicketTaskSuccess, TicketTaskFailed>();

export interface EditMachineTicketTask {
  id: number;
  ticketid: number;
  title: string;
  technicians: number[];
  status: TaskStatus;
  files: File[] | APIFile[];
}

interface EditTicketTaskFailed extends TicketTaskFailed {
  id: number;
}

export const editMachineTicketTask = createAsyncAction(
  Types.EDIT_MACHINE_TICKET_TASK,
  Types.EDIT_MACHINE_TICKET_TASK_SUCCEEDED,
  Types.EDIT_MACHINE_TICKET_TASK_FAILED
)<EditMachineTicketTask, TicketTaskSuccess, EditTicketTaskFailed>();

export interface DeleteMachineTicketTask {
  ticketid: number;
  id: number;
}

export const deleteMachineTicketTask = createAsyncAction(
  Types.DELETE_MACHINE_TICKET_TASK,
  Types.DELETE_MACHINE_TICKET_TASK_SUCCEEDED,
  Types.DELETE_MACHINE_TICKET_TASK_FAILED
)<DeleteMachineTicketTask, DeleteMachineTicketTask, IFailed>();

export interface DeleteMachineTicketTaskExpense {
  ticketid: number;
  taskid: number;
  id: number;
}

interface TicketTaskExpenseSuccess {
  expense: TaskExpense;
}

export interface CreateMachineTicketTaskExpense {
  ticketid: number;
  taskid: number;
  type: TaskExpenseType;
  description?: string;
  quantity: number;
  price?: number;
  date?: Date;
  regUserId?: number;
}

interface TaskPopupFailed extends IFailed {
  taskid: number;
}

export const createMachineTicketTaskExpense = createAsyncAction(
  Types.CREATE_MACHINE_TICKET_TASK_EXPENSE,
  Types.CREATE_MACHINE_TICKET_TASK_EXPENSE_SUCCEEDED,
  Types.CREATE_MACHINE_TICKET_TASK_EXPENSE_FAILED
)<CreateMachineTicketTaskExpense, TicketTaskExpenseSuccess, TaskPopupFailed>();

export interface DeleteMachineTicketTaskExpense {
  ticketid: number;
  taskid: number;
  id: number;
}

export const deleteMachineTicketTaskExpense = createAsyncAction(
  Types.DELETE_MACHINE_TICKET_TASK_EXPENSE,
  Types.DELETE_MACHINE_TICKET_TASK_EXPENSE_SUCCEEDED,
  Types.DELETE_MACHINE_TICKET_TASK_EXPENSE_FAILED
)<DeleteMachineTicketTaskExpense, DeleteMachineTicketTaskExpense, TaskPopupFailed>();

interface TicketTaskCommentSuccess {
  comment: TaskComment;
}

export interface CreateMachineTicketTaskComment {
  ticketid: number;
  taskid: number;
  comment: string;
}

export const createMachineTicketTaskComment = createAsyncAction(
  Types.CREATE_MACHINE_TICKET_TASK_COMMENT,
  Types.CREATE_MACHINE_TICKET_TASK_COMMENT_SUCCEEDED,
  Types.CREATE_MACHINE_TICKET_TASK_COMMENT_FAILED
)<CreateMachineTicketTaskComment, TicketTaskCommentSuccess, TaskPopupFailed>();

export interface DeleteMachineTicketTaskComment {
  ticketid: number;
  taskid: number;
  id: number;
}

export const deleteMachineTicketTaskComment = createAsyncAction(
  Types.DELETE_MACHINE_TICKET_TASK_COMMENT,
  Types.DELETE_MACHINE_TICKET_TASK_COMMENT_SUCCEEDED,
  Types.DELETE_MACHINE_TICKET_TASK_COMMENT_FAILED
)<DeleteMachineTicketTaskComment, DeleteMachineTicketTaskComment, TaskPopupFailed>();

export interface CreateMachineTicketTaskSign {
  signature: string;
  ticketId: number;
  tasks: number[];
}

export interface CreateMachineTicketTaskSignSuccess extends TaskSign {
  ticketId: number;
  tasks: number[];
}

export const createMachineTicketTaskSign = createAsyncAction(
  Types.CREATE_MACHINE_TICKET_TASK_SIGN,
  Types.CREATE_MACHINE_TICKET_TASK_SIGN_SUCCEEDED,
  Types.CREATE_MACHINE_TICKET_TASK_SIGN_FAILED
)<CreateMachineTicketTaskSign, CreateMachineTicketTaskSignSuccess, IFailed>();

export const toggleMachineTicketTechsView = createAction(
  Types.CREATE_MACHINE_TICKET_VIEW,
)<number | undefined>();

interface IOpenSign {
  ticketId: number;
  taskId?: number;
}

export const openSign = createAction(Types.OPEN_SIGN)<IOpenSign>();
export const closeSign = createAction(Types.CLOSE_SIGN)<void>();

export const openMachineTicketTask = createAction(Types.OPEN_MACHINE_TICKET_TASK)<Task>();
export const closeMachineTicketTask = createAction(Types.CLOSE_MACHINE_TICKET_TASK)<void>();

interface FetchMachinesForMarketplaceSuccess {
  machines: Machine[];
}

export const fetchMachinesForMarketplace = createAsyncAction(
  Types.FETCH_MARKETPLACE_MACHINES,
  Types.FETCH_MARKETPLACE_MACHINES_SUCCEEDED,
  Types.FETCH_MARKETPLACE_MACHINES_FAILED
)<void, FetchMachinesForMarketplaceSuccess, IFailed>();

export const closeMarketplaceForm = createAction(Types.CLOSE_MARKETPLACE_FORM)<void>();

interface ContactFormMachine {
  machineId: number;
}
export const openMarketplaceForm = createAction(Types.OPEN_MARKETPLACE_FORM)<ContactFormMachine>();

export interface SendMachineContact {
  id: number;
  name: string;
  email: string;
  telephone?: string;
  companyName?: string;
  message?: string;
  captcha: string;
}

export const sendMachineContact = createAsyncAction(
  Types.SEND_MACHINE_CONTACT,
  Types.SEND_MACHINE_CONTACT_SUCCEEDED,
  Types.SEND_MACHINE_CONTACT_FAILED
)<SendMachineContact, void, IFailed>();

interface IDashboardTasksSuccess {
  tasks: Task[];
}

export interface IFetchDashboardTasks {
  technicianId?: number;
}

export const fetchDashboardTasks = createAsyncAction(
  Types.FETCH_DASHBOARD_TASKS,
  Types.FETCH_DASHBOARD_TASKS_SUCCEEDED,
  Types.FETCH_DASHBOARD_TASKS_FAILED
)<IFetchDashboardTasks, IDashboardTasksSuccess, IFailed>();
